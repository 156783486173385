import React from 'react';
import { PRESSURE } from "../../app/lang/dic";
import TankCard from './TankCard'
import { Gauge } from './Gauge';

export const isPressurTank = tank => {
    return tank.liquid_type === PRESSURE;
}

export const getTankComponent = (tank, value, volume, asAlerts) => {
    if (isPressurTank(tank)) {
        return <Gauge value={value} volume={volume} height={400} />
    }
    else {
        return (
            <TankCard
                value={value}
                height={400}
                volume={volume}
                asAlerts={asAlerts}
            />
        )
    }
}