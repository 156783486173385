import React, { Component } from "react";
import { interpolateRgb } from 'd3-interpolate';
import LiquidFillGauge from 'react-liquid-gauge';
import { color } from 'd3-color';
import { isMobile } from "../../app/adminHelper";

const timeout = 5;
export class TankCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };

    if (this.props.asAlerts === false) {

      let tankColor = '#00A4D5';
      this.startColor = tankColor;
      this.endColor = tankColor;
      this.textColor = tankColor;
    }
    else {
      this.startColor = 'red';
      this.endColor = 'red';
      this.textColor = 'red';
    }

    this.tick = this.tick.bind(this);

    this.timeout = timeout;
  }

  componentDidMount() {
    this.timer = setInterval(this.tick, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    this.timeout -= 1;
    if (this.timeout === 0) {
      this.setState({ value: 0 });
    }

    if (this.timeout === -1) {
      this.timeout = timeout;
      this.setState({ value: this.props.value });
    }
  }

  getTankName() {
    if (this.props.tank) {
      return this.props.tank.name;
    }

    return null;
  }

  getRadiusFromHeight1() {
    var height = this.props.height * .8;
    let factor = 3.5;
    if (window.screen.width <= 330) {
      factor = 2.2;
    }
    else if (window.screen.width <= 360) {
      factor = 2.8;
    }
    else if (window.screen.width <= 375) {
      factor = 2.7;
    }

    let retVal = parseFloat(height, 10) * factor
    //console.log(AppLogger('retVal =' + retVal);
    return retVal;
  }

  getRadiusFromHeight() {
    //console.log(AppLogger('width  = ' + window.innerWidth);
    //console.log(AppLogger('Height = ' + window.innerHeight);
    var height = this.props.height * .8;
    //console.log(AppLogger('height ' + height);
    //console.log(AppLogger('innerHeight ' + window.innerHeight);
    return window.innerHeight * height / 350;

    //if (window.innerWidth <= 375){
    //  return 100;
    // }
    // else{
    //   return 140;
    // }
  }

  renderTank() {
    //const radius = this.getRadiusFromHeight();
    //const radius = 150;
    const interpolate = interpolateRgb(this.startColor, this.endColor);
    const fillColor = interpolate(this.state.value / 100);
    const gradientStops = [
      {
        key: '0%',
        stopColor: color(fillColor).darker(0.5).toString(),
        stopOpacity: 1,
        offset: '0%'
      },
      {
        key: '50%',
        stopColor: fillColor,
        stopOpacity: 0.75,
        offset: '50%'
      },
      {
        key: '100%',
        stopColor: color(fillColor).brighter(0.5).toString(),
        stopOpacity: 0.5,
        offset: '100%'
      }
    ];

    return (
      <div>
        <LiquidFillGauge
          // style={{ margin: '0 auto'/*,height:this.props.height*/ }}
          width={this.props.height * .8}
          height={this.props.height * .8}
          value={this.state.value}
          percent="%"
          textSize={1}
          textOffsetX={0}
          style={{ borderRadius: 0 }}
          textOffsetY={0}
          textRenderer={(props) => {
            const value = Math.round(props.value);
            const radius = Math.min(props.height / 2, props.width / 2);
            const textPixels = (props.textSize * radius / 2);
            const valueStyle = {
              fontSize: textPixels
            };
            const percentStyle = {
              fontSize: textPixels * 0.6
            };

            return (
              <tspan>
                <tspan className="value" style={valueStyle}>{value}</tspan>
                <tspan style={percentStyle}>{props.percent}</tspan>
              </tspan>
            );
          }}
          riseAnimation
          waveAnimation
          waveFrequency={1}
          waveAmplitude={4}
          gradient
          gradientStops={gradientStops}
          circleStyle={{
            fill: fillColor
          }}
          waveStyle={{
            fill: fillColor
          }}
          textStyle={{
            fill: this.textColor,
            fontFamily: 'Arial'
          }}
          waveTextStyle={{
            fill: color('#fff').toString(),
            fontFamily: 'Arial'
          }}
          onClick={() => {
            //this.setState({ value: Math.random() * 100 });
          }}
        />
        <center><h3 style={{ color: this.startColor }}><b>{this.props.volume}</b></h3></center>
      </div>);
  }

  render() {
    if (isMobile()) {
      return <div>this is the new</div>
    }
    return (
      <div>
        {this.renderTank()}
      </div>
    );
  }
};

export default TankCard;
