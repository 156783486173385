import TanksTable from "./db/TanksTable";
import { LogInHelper } from "./logInHelper";

export default class NotificationHelper {

    constructor() {
        new TanksTable().start(null, false);
    }

    getLevelNotifications() {
        let notifications = [];
        // scan for notifications
        let customerId = new LogInHelper().getLoggedInCustomerId();
        let tanks = new TanksTable().getCustomerTanks(customerId);
        for (let tankIndex in tanks) {
            let tanksNotifications = this.getLevelNotificationsOnTank(tanks[tankIndex]);
            tanksNotifications.map((notification)=>notifications.push(notification));
        }
        return notifications;
    }

    getLevelNotificationsOnTank(tank){
        let notifications = [];
        let lowestAlert = 100;
        for (let alertIndex in tank.level_alerts) {
            let level_alerts = tank.level_alerts[alertIndex];
            if (level_alerts.status === 'on') {
                if (lowestAlert > level_alerts.at_level){
                    lowestAlert = level_alerts.at_level;
                }
                //let alertObj = { name: tank.name, level: level_alerts.at_level }
                //notifications.push(alertObj);
            }
        }

        if (lowestAlert !== 100){
            let alertObj = { name: tank.name, level: lowestAlert }
            notifications.push(alertObj);
        }
        
        return notifications;
    }

    getHeartBeatNotification() {
        let notifications = [];
        // scan for notifications
        let customerId = new LogInHelper().getLoggedInCustomerId();
        let tanks = new TanksTable().getCustomerTanks(customerId);
        for (let tankIndex in tanks) {
            let tanksNotifications = this.getHeartBeatNotificationForTank(tanks[tankIndex]);
            tanksNotifications.map((notification)=>notifications.push(notification));
        }
        return notifications;
    }

    getHeartBeatNotificationForTank(tank){
        let notifications = [];
        if (tank.heartbeat_ok !== true){
            notifications.push({ name: tank.name });
        }
        return notifications;
    }

}