import Dashboard from "views/Dashboard/Dashboard";
import Customers from "views/Admin/Customers"
import Tanks from "views/Admin/Tanks"
import Users from "views/Admin/Users"
import Alerst from "views/Admin/Alerts"
import Logger from "../views/Admin/Logger";
//import Icons from "../views/Icons/Icons"
import Graph from "../views/Graph/Graph"
import Data from "../views/Data/Data"
import Locations from "../views/Location/Locations"
import LogOut from "./../views/App/LogOut"
import Install from "./../views/Install/Install"
//import Indicators from "../views/Indicators/Indicators";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  /*
  {
    path: "/sysytemhelth",
    name: "System Helth",
    icon: "pe-7s-flag",
    component: Indicators
  },*/
  {
    path: "/graph",
    name: "Graph",
    icon: "pe-7s-graph1",
    component: Graph
  },
  {
    path: "/data",
    name: "Data",
    icon: "pe-7s-note2",
    component: Data
  },
  {
    path: "/locations",
    name: "Locations",
    icon: "pe-7s-map-2",
    component: Locations
  },
  {
    path: "/logout",
    name: "Log Out",
    icon: "pe-7s-power",
    component: LogOut
  },
  {
    path: "/install",
    name: "Install",
    icon: "pe-7s-upload",
    component: Install,
  },
  //{
  //  path: "/user",
  //  name: "User Profile",
  //  icon: "pe-7s-user",
  //  component: UserProfile
  //},
  //{
  //  path: "/table",
  //  name: "Table List",
  //  icon: "pe-7s-note2",
  //  component: TableList
  //},
  //{
  //  path: "/typography",
  //  name: "Typography",
  //  icon: "pe-7s-news-paper",
  //  component: Typography
  //},



  {
    path: "/customers",
    name: "Admin - Customers",
    icon: "pe-7s-users",
    component: Customers,
    show: "admin"
  },
  // {
  //   path: "/Management",
  //   name: "Admin - Building Mng",
  //   icon: "pe-7s-users",
  //   component: Customers,
  //   show: "admin"
  // },
  {
    path: "/tanks",
    name: "Admin - Tanks",
    icon: "pe-7s-server",
    component: Tanks,
    show: "admin"
  },
  {
    path: "/users",
    name: "Admin - Users",
    icon: "pe-7s-users",
    component: Users,
    show: "admin"
  },
  {
    path: "/alerts",
    name: "Admin - Alerts",
    icon: "pe-7s-gleam",
    component: Alerst,
    show: 'admin'
  },
  {
    path: "/logger",
    name: "Admin - logs",
    icon: "pe-7s-rocket",
    component: Logger,
    show: "admin"
  },

  //{
  //  path: "/icons",
  //  name: "Icons",
  //  icon: "pe-7s-science",
  //  component: Icons,
  //  show: "admin"
  //},
  //{ path: "/maps", name: "Maps", icon: "pe-7s-map-marker", component: Maps },
  //{
  //  path: "/notifications",
  //  name: "Notifications",
  //  icon: "pe-7s-bell",
  //  component: Notifications
  //},
  //{
  //  upgrade: true,
  ///  path: "/upgrade",
  //  name: "Upgrade to PRO",
  //  icon: "pe-7s-rocket",
  //  component: Upgrade
  //},
  { redirect: true, path: "/", to: "/dashboard", name: "Dashboard" }
];

export default dashboardRoutes;
