import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import dashboardRoutes from "routes/dashboard.jsx";
import { LogInHelper } from "../../app/logInHelper";
import LogIn from "../../views/App/login.jsx"
import UsersTable from "../../app/db/UsersTable";
import TanksTable from "../../app/db/TanksTable";
import CustomersTable from "../../app/db/CustomersTable";
import TanksReading from "../../app/db/TankReadings";
import { appIsReady } from "../../app/adminHelper"
import { setGraphFilteredTank, setDataFilteredTank } from "../../app/readingsHelper"
import { AppLogger } from "app/Logger";
import "./../../assets/css/bubble.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _notificationSystem: null,
      appDbReady: 0
    };
  }

  componentDidMount = () => {
    // read mandatory tables 
    new UsersTable().start(this.userTableReady, false);
  }

  userTableReady = () => {
    console.log(AppLogger("userTableReady"));
    new TanksTable().start(this.tanksTableReady, false);
  }

  tanksTableReady = () => {
    new CustomersTable().start(this.customersTableReady, false);
  }

  customersTableReady = () => {
    new TanksReading().getLastReading(this.allIsReady);
  }

  allIsReady = () => {
    let tanks = new TanksTable().getCustomerTanks(new LogInHelper().getLoggedInCustomerId());

    if (tanks.length > 0) {
      setGraphFilteredTank(tanks[0]);
      setDataFilteredTank(tanks[0]);
    }

    appIsReady(true);
  }

  dbReady = () => {
    this.setState({ appDbReady: this.state.appDbReady + 1 });
  }

  /*
handleNotificationClick(position) {
  var color = Math.floor(Math.random() * 4 + 1);
  var level;
  switch (color) {
    case 1:
      level = "success";
      break;
    case 2:
      level = "warning";
      break;
    case 3:
      level = "error";
      break;
    case 4:
      level = "info";
      break;
    default:
      break;
  }
  this.state._notificationSystem.addNotification({
    title: <span data-notify="icon" className="pe-7s-gift" />,
    message: (
      <div>
        Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
        every web developer.
      </div>
    ),
    level: level,
    position: position,
    autoDismiss: 15
  });
}

componentDidMount() {
  this.setState({ _notificationSystem: this.refs.notificationSystem });
  var _notificationSystem = this.refs.notificationSystem;
  var color = Math.floor(Math.random() * 4 + 1);
  var level;
  switch (color) {
    case 1:
      level = "success";
      break;
    case 2:
      level = "warning";
      break;
    case 3:
      level = "error";
      break;
    case 4:
      level = "info";
      break;
    default:
      break;
  }
  _notificationSystem.addNotification({
    title: <span data-notify="icon" className="pe-7s-gift" />,
    message: (
      <div>
        Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for
        every web developer.
      </div>
    ),
    level: level,
    position: "tr",
    autoDismiss: 15
  });
}
*/
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      // document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    let logInHelper = new LogInHelper();
    if (!logInHelper.isLogIn()) {
      return (<LogIn />);
    }
    else {
      return (
        <div className="">
          <div id="bubble-wrap" style={{
            backgroundColor: '#2a2a72',
            backgroundImage: 'linear-gradient(340deg, black 0%, #2a2a72 15%, #00b4ff 50%, white 95%)'
          }}>
            <div className="bubble x1"></div>
            <div className="bubble x2"></div>
            <div className="bubble x3"></div>
            <div className="bubble x4"></div>
            <div className="bubble x5"></div>
            <div className="bubble x6"></div>
            <div className="bubble x7"></div>
            <div className="bubble x8"></div>
            <div className="bubble x9"></div>
            <div className="bubble x10"></div>
          </div>
          {/*<NotificationSystem ref="notificationSystem" style={style} />*/}
          <Sidebar {...this.props} />
          <div id="main-panel" className="main-panel" ref="mainPanel">
            <Header {...this.props} />
            <Switch>
              {dashboardRoutes.map((prop, key) => {
                if (prop.name === "Notifications")
                  return (
                    <Route
                      path={prop.path}
                      key={key}
                      render={routeProps => (
                        <prop.component
                          {...routeProps}
                          handleClick={this.handleNotificationClick}
                        />
                      )}
                    />
                  );
                if (prop.redirect)
                  return <Redirect from={prop.path} to={prop.to} key={key} />;
                return (
                  <Route path={prop.path} component={prop.component} key={key} />
                );
              })}
            </Switch>
          </div>
        </div>
      );
    }
  }
}

export default Dashboard;
