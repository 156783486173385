import React from "react";
import { Grid, Row, Col, MenuItem, DropdownButton } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { LogInHelper } from "app/logInHelper";
import { isAppReady } from 'app/adminHelper'
import TanksTable from "../../app/db/TanksTable"
import CustomersTable from "../../app/db/CustomersTable"
import { TankForm } from "views/Admin/TankForm";
import { renderTanksParams } from "views/Tanks/Tanks";
import { AlertsForm } from "./AlerstForm";
import { isTankFiltered, renderFilterTanksCard } from "../Tanks/Tanks";
import { AppLogger } from "app/Logger";
import NotificationHelper from "../../app/notificationHelper";
import { getButtonStyle } from "../../app/adminHelper";

export default class Tanks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showTankForm: false,
            showAlerstForm: false,
            filterStr: "",
            tank: null,
            customer: null,
            tanks: []
        };
        this.buttonStyle = getButtonStyle();
    }

    componentDidMount() {
        //update reading timer
        this.timer = setInterval(this.tick, 250);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    tick = () => {
        if (isAppReady()) {
            this.tanksReady();
            clearInterval(this.timer);
        }
    }

    readTanks = () => {
        let forceRead = true;
        if (this.state.tanks.length === 0) {
            forceRead = false;
        }
        console.log(AppLogger("readTanks"));
        new TanksTable().start(this.tanksReady, forceRead);
    }

    tanksReady = () => {
        let tt = new TanksTable();
        this.setState({ tanks: tt.getTanks().sort((a, b) => a.name > b.name ? 1 : -1) });
    }

    closeTankForm() {
        this.setState({ showTankForm: false, tank: null, customer: null, tanks: [] });
        new TanksTable().start(this.tanksReady, true);
    }

    editTank(tank) {
        let ct = new CustomersTable();
        let customer = ct.getCustomer(tank.customer);
        this.setState({ showTankForm: true, tank: tank, customer: customer });
    }

    editAlert = (tank) => {
        this.setState({ showAlerstForm: true, tank: tank });
    }

    closeAlertsForm = () => {
        this.setState({ showAlerstForm: false, tank: null, customer: null });
    }

    deleteTank = (tank) => {
        let confirmText = 'Deleting Tank: Are you sure you want to delete this tanks?';
        if (window.confirm(confirmText)) {
            let tt = new TanksTable();
            tt.deleteTank(tank.imei, this.readTanks);
        }
    }

    renderManageTankButton(tank, index) {
        let title =
            <div>
                <div>{"Settings"}</div>
            </div>;
        return (
            <DropdownButton
                bsStyle="primary"
                style={this.buttonStyle}
                bsSize="large"
                title={title}
                key={index}
                id={`dropdown-basic-${index}`}
            >
                <MenuItem eventKey="1" onClick={() => this.editTank(tank)}>Edit Tank</MenuItem>
                <MenuItem eventKey="2" onClick={() => this.editAlert(tank)}>Edit Alerts</MenuItem>
                <MenuItem divider />
                <MenuItem eventKey="3" onClick={() => this.deleteTank(tank)}>Delete Tank</MenuItem>
            </DropdownButton>
        );
    }

    renderTanks = () => {
        return (
            this.state.tanks.map((tank, index) => {
                if (isTankFiltered(tank, this.state.filterStr)) {
                    return null;
                }
                else {
                    return (
                        < Col xs={12} md={6} key={index} >
                            <Card
                                opacity={false}
                                hCenter={true}
                                statsIcon="fa"
                                id="EditTank"
                                title={
                                    <div>
                                        <div>{tank.name}</div>
                                    </div>}
                                category=""
                                stats={renderTanksParams(tank, null, new NotificationHelper())}
                                content={<center>{this.renderManageTankButton(tank, index)}</center>}
                            />
                        </Col >
                    )
                }
            }
            )
        )
    }

    render() {
        let isAdmin = new LogInHelper().isAdmin();

        if (!isAdmin) {
            return null;
        }
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        {renderFilterTanksCard(this)}
                    </Row>
                    <Row>
                        {this.renderTanks()}
                    </Row>
                </Grid>
                <TankForm show={this.state.showTankForm}
                    onHide={() => this.closeTankForm()}
                    tank={this.state.tank}
                    customer={this.state.customer} />
                <AlertsForm show={this.state.showAlerstForm}
                    tank={this.state.tank}
                    onHide={() => this.closeAlertsForm()} />
            </div>
        );
    }
}