
import React from "react";
import { LogInHelper } from "../../app/logInHelper";
import { Redirect } from 'react-router-dom'

export default class LogOut extends React.Component {

    componentDidMount() {
        let logIn = new LogInHelper();
        logIn.logOut();
        window.location.reload();
    }

    render() {
        return (
            <div>
                <Redirect to='/dashboard' />
            </div>
        );
    }
}