import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "assets/img/orbitBanner3000.png";
import { LogInHelper } from "app/logInHelper";
import dashboardRoutes from "routes/dashboard.jsx";
import { isAppReady } from 'app/adminHelper'
import { getMenuTextColor } from 'app/adminHelper'
import { getVersion, isMobile } from '../../app/adminHelper'

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      appIsReady: false
    };
  }
  componentDidMount = () => {
    this.timer = setInterval(this.checkIfAppIsReady, 1000);
    this.checkIfAppIsReady();
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  checkIfAppIsReady = () => {
    if (isAppReady()) {
      this.setState({ appIsReady: true });
      clearInterval(this.timer);
    }
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  showInstall = () => {

  }

  isFromBrowser = () => {
    var isPWAinBrowser = true;
    // replace standalone with fullscreen or minimal-ui according to your manifest
    if (matchMedia('(display-mode: standalone)').matches) {
      // Android and iOS 11.3+
      isPWAinBrowser = false;
    } else if ('standalone' in navigator) {
      // useful for iOS < 11.3
      isPWAinBrowser = !navigator.standalone;
    }

    return isPWAinBrowser;
  }

  render() {
    //const sidebarBackground = {
    //  backgroundImage: "url(" + imagine + ")"
    //};
    let logInHelper = new LogInHelper();
    let isAdmin = logInHelper.isAdmin();
    let logInUser = logInHelper.getLogedInUser();

    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={'primarySide'}
      >
        <div className="sidebar-background" />
        <div className="logo" style={{ backgroundColor: 'white', display: 'flex' }}>
          <img src={logo} alt="logo_image" style={{ width: '100%', height: '90%', margin: 'auto', top: '25%' }} />
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {dashboardRoutes.map((prop, key) => {
              let show = true;
              if ((prop.show && prop.show === 'admin' && !isAdmin) ||
                (prop.redirect && prop.redirect === true)) {
                show = false;
              }
              if (prop.name === "Install") {
                show = false;
                if (isMobile() && this.isFromBrowser()) {
                  show = true;
                }
              }
              if (show)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} style={{
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        width: '100%',
                        color: getMenuTextColor()
                      }} />
                      <div>
                        <center><h4 style={{ color: getMenuTextColor() }}><b>{prop.name}</b></h4></center>
                      </div>
                    </NavLink>
                  </li>
                );
              return null;
            })}
            <center>
              <hr />
              <div style={{ color: getMenuTextColor() }}><b>{getVersion()}</b></div>
              <br />
              <div style={{ color: getMenuTextColor() }}><b>User Name - {logInUser}</b></div>
              <hr />
            </center>
          </ul>
        </div>
      </div>
    );
  }
}

export default Sidebar;
