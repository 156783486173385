import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import RangeHelper from "./../Dashboard/RangeHelper";
import RangeView from "./../Dashboard/RangeView";
import Card from "components/Card/Card";
import VisGraph from "components/App/VisGraph.jsx"
import { translate } from "../../app/lang/lang";
import { getFilteredReadings } from "../../app/readingsHelper"
import { isAppReady, getBackgroundColor } from "../../app/adminHelper";
import TanksTable from "../../app/db/TanksTable";
import { setGraphFilteredTank, getGraphFilteredTank } from "../../app/readingsHelper"
import Spinner from "../App/Spinner"

class Graph extends Component {

    constructor(props) {
        super(props);

        this.state = { graphReadings: [], rangeIndex: 0, rangeReady: false, selectedTanks: [], air: [], colors: [] }
    }

    componentDidMount() {
        //update reading timer
        this.timer = setInterval(this.tick, 250);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    tick = () => {
        if (isAppReady()) {
            let rh = new RangeHelper();
            //rh.getRangeForHourse(rh.getFirstRangeInHours(), this.rangeReady, "graph");
            this.hoursSelected(rh.getFirstRangeInHours(), 0);
            clearInterval(this.timer);
        }
    }

    hoursSelected = (hours, index) => {
        this.setState({
            graphReadings: [], rangeIndex: index,
            index: index, hours: hours, start: null, end: null, rangeReady: false
        });
        //new RangeHelper().getRangeForHourse(hours, this.rangeReady, this.getSelectedTank());
        new RangeHelper().getRangeForHourse(hours, this.rangeReady, null);

    }

    rangeSelected = (start, end) => {
        let rh = new RangeHelper();
        this.setState({
            rangeIndex: new RangeHelper().getRanges().length - 1,
            index: null, hours: null, start: start, end: end, rangeReady: false
        })
        rh.getRangeFromTo(start, end, this.rangeReady, null);
    }

    rangeReady = (range) => {
        this.setState({ graphReadings: range, rangeReady: true });
    }

    rangeReadingToGraphData() {
        let retArray = [];
        let maxX = 0;
        let readings = getFilteredReadings(this.state.graphReadings, "");
        //console.log("readings    ");
        //console.log(readings);
        let tt = new TanksTable();
        let maxCapacity = 0;
        for (let index in readings) {
            let current = readings[index];
            let tmpData = [];
            let tank = tt.getTank(readings[index].imei);
            if (tank === null || tank.capacity === null) {
                continue;
            }
            for (let currentIndex in current.levels) {
                let litters = current.levels[currentIndex] * tank.capacity / 100;
                let gallons = Math.floor(litters * 0.264172);

                let obj = {
                    x: current.times[currentIndex],
                    y: gallons,
                    liquidHigh: current.levels[currentIndex],
                    tankName: tank.name
                };
                tmpData.push(obj);
                if (current.times[currentIndex] > maxX) {
                    maxX = current.times[currentIndex];
                }


                if (tank.capacity * 0.264172 > maxCapacity) {
                    maxCapacity = tank.capacity * 0.264172;
                }
            }
            retArray.push(tmpData);
        }

        // add the max object - TODO fix
        let tmpData = [];
        tmpData.push({ x: maxX, y: maxCapacity * 1.2, tankName: "" }); // add 20% to the graph
        retArray.push(tmpData);
        tmpData = [];
        tmpData.push({ x: maxX, y: 0, tankName: "" });
        retArray.push(tmpData);

        return retArray;
    }

    tankSelected = (tank) => {
        setGraphFilteredTank(tank);
        if (this.state.start === null) {
            this.hoursSelected(this.state.hours, this.state.index);
        }
        else {
            this.rangeSelected(this.state.start, this.state.end);
        }
    }

    getSelectedTank = () => {
        return getGraphFilteredTank();
    }

    getSelectedTanks = () => {
        return this.state.selectedTanks;
    }

    allSelectedTanks = (tanks) => {
        this.setState({ selectedTanks: tanks })
    }

    getfilteredData = (data) => {
        var tempData = [];
        if (this.state.selectedTanks.length > 0) {
            data.map((tankData) => {
                this.state.selectedTanks.map((selectedTank) => {
                    if (tankData.length > 0) {
                        if (tankData[0].tankName !== "") {
                            if (tankData[0].tankName === selectedTank.value) {
                                tempData.push(tankData);
                            }
                        }
                    }
                    return null
                })
                return null
            })
        } else {
            tempData = data;
        }

        return tempData;
    }

    updateAir = (airValues) => {
        this.setState({ air: airValues })
    }

    updateColors = (colors) => {
        this.setState({ colors: colors })
    }

    render() {
        //<Spinner />


        let height = this.props.fromDashboard === true ? '' : '120%';
        if (this.state.rangeReady === false) {
            return (
                <div className="content" style={{ backgroundColor: getBackgroundColor(), height: height }}>
                    <Card
                        hCenter={true}
                        title="Loading Graph..."
                        content={<center><Spinner /></center>}
                    />
                </div>
            )
        }
        let cardId = "visGraph"
        let data = this.rangeReadingToGraphData();
        //console.log(data);
        let rh = new RangeHelper();

        let selectedRange = rh.getSelectedString(this.state.rangeIndex);

        let numberOfPoints = 100;
        if (rh.getRangeInHour(this.state.rangeIndex) !== null) {
            numberOfPoints = rh.getRangeInHour(this.state.rangeIndex) * 8;
        }

        let sortSelected = []
        if (this.state.selectedTanks) {
            data.map((tank) => {
                if (tank.length > 0) {
                    let tankName = tank[0].tankName;
                    let selected = this.state.selectedTanks.find(selected => selected.value === tankName)
                    if (selected) {
                        sortSelected.push(selected);
                    }
                }
                return null
            })
        }


        return (
            <div className="content" style={{ backgroundColor: getBackgroundColor(), height: height }}>
                <Col md={12}>
                    <Card
                        opacity={!this.props.fromDashboard}
                        id={cardId}
                        title={translate("volumeHistory")}
                        category={
                            <div style={{ width: '100%', fontSize: 20 }}>
                                <div style={{ fontWeight: 'bold' }}>
                                    Show data for {selectedRange}
                                </div>
                                {this.state.selectedTanks.length > 0 ?
                                    <div style={{ textDecoration: 'underline' }}>Tanks:</div> : null
                                }
                                <Row>
                                    {sortSelected.map((tank, index) => {
                                        let airValue = this.state.air.find(element => element.tankName === tank.value);
                                        return (
                                            <Col>
                                                <div style={{ display: 'inline-block', fontWeight: 'bold', color: this.state.colors[index % this.state.colors.length] }}>
                                                    {tank.value}
                                                </div>
                                                <div style={{ display: 'inline' }}>
                                                    {airValue ? ' = ' + airValue.y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' gallons ' + (airValue.liquidHigh ? '(' + airValue.liquidHigh + '%)' : '') : ''}
                                                </div>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </div>
                        }
                        hCenter={true}
                        stats={<RangeView
                            hoursSelected={this.hoursSelected}
                            rangeSelected={this.rangeSelected}
                            tankSelected={this.tankSelected}
                            getSelectedTank={this.getSelectedTank}
                            onTankSelected={this.allSelectedTanks}
                            setSelectedTanks={this.getSelectedTanks}
                            isMulti={true} />}
                        content={
                            <div style={{ height: '380px' }}>
                                <VisGraph
                                    data={this.getfilteredData(data)}
                                    maxPoints={numberOfPoints}
                                    updateAirValues={this.updateAir}
                                    updateColors={this.updateColors}
                                />
                            </div>
                        }
                    />
                </Col>
            </div>
        );
    }
}

export default Graph;
