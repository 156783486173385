
import React from "react";
import { Modal, Button } from 'react-bootstrap'
import DateRangePickerWrapper from "components/App/DateAndTime.jsx";
import { MenuItem, DropdownButton } from 'react-bootstrap';


class DateAndTimeModal extends React.Component {

    constructor(props) {
        super(props);
        // create time array
        this.timeArray = this.createTimeArray();

        this.state = { startTime: "00:00 AM", endTime: "24:00 PM", startDate: new Date(), endDate: new Date() }
    }

    createTimeArray = () => {
        let timeArray = [];
        for (let hour = 0; hour < 24; hour++) {
            let hourStr = "" + hour;
            if (hour < 10) {
                hourStr = "0" + hour;
            }

            timeArray.push(hourStr + ":00");
            timeArray.push(hourStr + ":30");
        }

        return timeArray;

    }
    dateSelected = (start, end) => {
        this.setState({ startDate: start, endDate: end });
    }

    closeModal = () => {
        this.props.onHide(
            this.state.startDate, this.state.endDate,
            this.state.startTime, this.state.endTime);
        this.setState({ startTime: "00:00 AM", endTime: "24:00 PM", startDate: new Date(), endDate: new Date() });
    }

    quickSelection = (time) => {
        this.props.onHide(null, null, time, null);
    }

    render() {
        return (
            <Modal
                {...this.props}
                bsSize="small"
                aria-labelledby="contained-modal-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm">Date Range Selection</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DateRangePickerWrapper onHide={this.dateSelected}
                        startDateId={"2"} endDateId={"2"} />
                </Modal.Body>
                <div>
                    <center>
                        Select Start & End Time
                    </center>
                    <p />
                </div>
                <div><center>
                    <DropdownButton title={this.state.startTime} id="dropdown-size-medium"
                        onSelect={(e) => this.setState({ startTime: this.timeArray[e] })}>
                        {this.timeArray.map((str, index) => {
                            return <MenuItem key={index} eventKey={index}><strong>{str}</strong></MenuItem>;
                        })}
                    </DropdownButton>&nbsp;&nbsp;
                    <DropdownButton title={this.state.endTime} id="dropdown-size-medium"
                        onSelect={(e) => this.setState({ endTime: this.timeArray[e] })}>
                        {this.timeArray.map((str, index) => {
                            return <MenuItem key={index} eventKey={index}><strong>{str}</strong></MenuItem>;
                        })}
                    </DropdownButton>
                </center></div>
                <p />
                <Modal.Footer>
                    <Button onClick={this.closeModal}>Select</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default DateAndTimeModal;

