import React from "react";
import { DropdownButton, MenuItem } from "react-bootstrap";
import TanksTable from "../../app/db/TanksTable";
import { LogInHelper } from "../../app/logInHelper";
import { getButtonStyle } from '../../app/adminHelper'

export default class TanksSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTankName: null
        };

        let customerId = new LogInHelper().getLoggedInCustomerId();
        this.tanks = new TanksTable().getCustomerTanks(customerId);
    }

    tankSelected = (index) => {

        this.setState({ selectedTankName: this.tanks[index].name });
        this.props.tankSelected && this.props.tankSelected(this.tanks[index]);
    }

    getTankName = () => {
        if (this.state.selectedTankName === null) { // not set yet
            return this.props.getSelectedTank().name
        }
        else {
            return this.state.selectedTankName
        }
    }

    render() {
        let tankName = this.getTankName()
        return (
            <DropdownButton title={"Tank - " + tankName} bsStyle="primary"
                style={getButtonStyle()}
                id="bg-nested-dropdown"
                drop={'start'}>
                {
                    this.tanks.map((tank, index) => {
                        return (
                            <MenuItem eventKey={index} key={index} onClick={() => this.tankSelected(index)}>{tank.name}</MenuItem>
                        )
                    })
                }
            </DropdownButton>
        )
    }
}