import { AppLogger } from "app/Logger";
import UsersTable from '../../app/db/UsersTable'
import { Cookies } from 'react-cookie';
import React from "react";
import { Modal, FormControl, Button } from 'react-bootstrap';
import { LogInHelper } from '../../app/logInHelper.jsx'

export default class LogIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isLogIn: false, showAlert: false, showButton: false, debugMessage: "start" }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (new LogInHelper().isLogIn()) {
            this.setState({ isLogIn: true });
        }

        new UsersTable().start(this.usersTableReady, true);
    }

    usersTableReady = () => {
        this.setState({ showButton: true });
    }

    handleLogIn() {
        console.log(AppLogger("handleLogIn"));
        console.log(AppLogger(this.state.userName));
        console.log(AppLogger(this.state.password));

        let ut = new UsersTable();
        let user = ut.getUser(this.state.userName);

        let loginAlert = null;
        if (user) {
            if (user.passwd === this.state.password) {
                console.log(AppLogger("handleLogIn 1111"));
                try {
                    console.log(AppLogger("handleLogIn22222"));
                    window.localStorage.setItem('user', this.state.userName);
                    console.log(AppLogger("handleLogIn33333"));
                }
                catch (e){
                    console.log(AppLogger("handleLogIn4444"));
                    let cookie = new Cookies();
                    cookie.set('user', this.state.userName);
                }

                this.setState({ isLogIn: true, logInAlert: null });
                window.location.reload();
                return;
            }
            else {
                loginAlert = "the password for " + this.state.userName + " is incorrect";
            }
        }
        else {
            loginAlert = this.state.userName + " not found";
        }
        this.setState({ isLogIn: false, logInAlert: loginAlert });
    }

    handleChange(e) {
        const target = e.target;

        this.setState({
            [target.name]: target.value,
            logInAlert: null
        });
    }

    render() {
        return (
            <div>
                {this.state.isLogIn === false &&
                    <Modal
                        show={true}
                        bsSize='lg'
                        onHide={this.handleHide}
                        container={this}
                        aria-labelledby="contained-modal-title"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title">
                                LogIn
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            User Name:
                            <FormControl
                                type="text"
                                placeholder="user name"
                                onChange={this.handleChange}
                                name="userName"
                            />
                            Password:
                            <FormControl
                                type="text"
                                placeholder="password"
                                onChange={this.handleChange}
                                name="password"
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            {this.state.logInAlert &&
                                <center>
                                    Failed to Login: {this.state.logInAlert}
                                </center>
                            }
                            {
                                this.state.showButton &&
                                <Button onClick={() => this.handleLogIn()}>LogIn</Button>
                            }
                        </Modal.Footer>
                    </Modal>
                }
            </div>
        );
    }

}