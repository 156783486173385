import React from "react";
import { AppLogerMessages } from "app/Logger"

export default class Logger extends React.Component {

    render() {
        return (
            <div style={{ backgroundColor: 'white', opacity: '80%' }}>
                {AppLogerMessages().map((msg, index) =>
                    <div key={index}>
                        {msg}
                    </div>)
                }
            </div>
        )
    }
}