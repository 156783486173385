import React, { Component } from "react";
import RangeHelper from "./../Dashboard/RangeHelper";
import { translate } from "../../app/lang/lang";
import { getFilteredReadings } from "../../app/readingsHelper"
import { isAppReady, getBackgroundColor } from "../../app/adminHelper";
import TableList from "../TableList/TableList"
import RangeView from "./../Dashboard/RangeView";
import { setDataFilteredTank, getDataFilteredTank } from "../../app/readingsHelper"
import Spinner from "../App/Spinner"
import Card from "components/Card/Card";
import { Grid, Col } from "react-bootstrap";

class Data extends Component {

    constructor(props) {
        super(props);
        this.state = { tableReadings: [], rangeIndex: 0, rangeReady: false }
    }

    componentDidMount() {
        //update reading timer
        this.timer = setInterval(this.tick, 250);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    tick = () => {
        if (isAppReady()) {
            //rh.getRangeForHourse(rh.getFirstRangeInHours(), this.rangeReady, "data");
            clearInterval(this.timer);
            this.hoursSelected(new RangeHelper().getFirstRangeInHours(), 0);
        }
    }

    hoursSelected = (hours, index) => {
        this.setState({
            tableReadings: [], rangeIndex: index,
            index: index, hours: hours, start: null, end: null, rangeReady: false
        });
        new RangeHelper().getRangeForHourse(hours, this.rangeReady, this.getSelectedTank());
    }

    rangeSelected = (start, end) => {
        let rh = new RangeHelper();
        this.setState({
            rangeIndex: new RangeHelper().getRanges().length - 1,
            index: null, hours: null, start: start, end: end, rangeReady: false
        })
        rh.getRangeFromTo(start, end, this.rangeReady, this.getSelectedTank());
    }

    tankSelected = (tank) => {
        setDataFilteredTank(tank);
        // update the data according to the last selection
        if (this.state.start !== null) {
            this.rangeSelected(this.state.start, this.state.end);
        }
        else {
            this.hoursSelected(this.state.hours, this.state.index);
        }
    }

    getSelectedTank = () => {
        return getDataFilteredTank();
    }

    rangeReady = (range) => {
        this.setState({ tableReadings: range, rangeReady: true });
    }

    render() {
        let height = this.props.fromDashboard === true ? '' : '120%';

        if (this.state.rangeReady === false) {
            return (
                <div className="content" style={{ backgroundColor: getBackgroundColor(), height: height }}>
                    <Col md={12}>
                        <Card
                            hCenter={true}
                            title="Loading Data..."
                            content={<center><Spinner /></center>}
                        /></Col>
                </div>
            )
        }
        this.tankSelected = this.tankSelected.bind(this);
        let selectedRange = new RangeHelper().getSelectedString(this.state.rangeIndex) + " for tank: " + this.getSelectedTank().name;
        return (
            <div className="content" style={{ backgroundColor: getBackgroundColor(), height: height }}>
                <Grid fluid alig>
                    <TableList
                        fromDashboard={this.props.fromDashboard}
                        rangeReadings={getFilteredReadings(this.state.tableReadings, "")}
                        title={translate("latestData")}
                        rangeButtons={<RangeView
                            hoursSelected={this.hoursSelected}
                            rangeSelected={this.rangeSelected}
                            tankSelected={this.tankSelected}
                            getSelectedTank={this.getSelectedTank}
                            // setSelectedTanks={this.tankSelected}
                            isMulti={false} />}
                        category={selectedRange} />
                </Grid>
            </div>
        )
    }
}

export default Data;
