export default class serviceWorkerConfig {

    onUpdate(registration){
        console.log("onUpdate new version - auto refresh");
        window.location.reload();
    }

    onSuccess(registration){
        console.log("onSuccess");
    }
}