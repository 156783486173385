import React, { Component } from "react";

import { getPrimaryTextColor } from '../../app/adminHelper'

export class Card extends Component {
  render() {
    let colorStyle = { color: getPrimaryTextColor() };
    if (this.props.color) {
      colorStyle = { color: this.props.color };
    }
    return (
      <div className={"card" + (this.props.plain ? " card-plain" : "")} style={{ opacity: this.props.opacity === false ? '100%' : '88%', backgroundColor: this.props.opacity === false ? '#e5f7fe' : '' }}>
        <div className={"header" + (this.props.hCenter ? " text-center" : "")} >
          <h4 className="title"><b><font color={colorStyle.color}>{this.props.title}</font></b></h4>
        </div>
        <p className="category" style={Object.assign(colorStyle, { paddingLeft: '1%' })}>{this.props.category}</p>
        <div
          className={
            "content" +
            (this.props.ctAllIcons ? " all-icons" : "") +
            (this.props.ctTableFullWidth ? " table-full-width" : "") +
            (this.props.ctTableResponsive ? " table-responsive" : "") +
            (this.props.ctTableUpgrade ? " table-upgrade" : "")
          }
        >

          {this.props.content}

          {/*<div className="footer">*/}
          <div>
            {this.props.legend}
            {/*this.props.stats != null ? <hr /> : ""*/}
            <div className="stats">
              <i className={this.props.statsIcon} style={colorStyle} />
              <div style={colorStyle}>&nbsp;{this.props.stats}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Card;
