import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx"
import TanksTable from "../../app/db/TanksTable.jsx"
import { LogInHelper } from "app/logInHelper";
import NotificationHelper from "../../app/notificationHelper"
import TanksReading from "../../app/db/TankReadings.jsx";
import { levelAlertView, heartBeatView } from "../Notifications/TanksNotifications"
import { renderTanks, renderCompressedTanks, renderFilterTanksCard } from "../Tanks/Tanks.jsx"
import Graph from "../Graph/Graph";
import Data from "../Data/Data";
import Locations from "../Location/Locations";
import { getFilteredReadings } from "../../app/readingsHelper"
import { isAppReady, getBackgroundColor, isMobile } from "../../app/adminHelper"
import Spinner from "../App/Spinner"
import { AppLogger } from "app/Logger";
import Indicators from "../Indicators/Indicators.jsx";
import AlarmsManager from "../../app/AlarmsManager.jsx";

class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tanks: [],
      lastTanksReadings: [],
      filterStr: "",
      customrtWithNoTanks: false
    }
    this.getRangeReadingOnce = true;
    this.tickToggle = true;
  }

  componentDidMount() {
    //update reading timer
    this.timer = setInterval(this.tick, 35000);
    this.fastTimer = setInterval(this.fastTick, 250);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
    clearInterval(this.fastTimer);
  }

  tick = () => {
    if (this.tickToggle) {
      // update the last readings
      new TanksReading().getLastReading(this.tanksReadingReady);
    }
    else {
      // read the tanks table for alers
      console.log(AppLogger("tick"));
      new TanksTable().start(this.dataIsReady, true);
    }
    this.tickToggle = this.tickToggle ? false : true;
  }

  fastTick = () => {
    if (isAppReady()) {
      this.dataIsReady();
      clearInterval(this.fastTimer);
    }
  }

  dataIsReady = () => {
    // check is this is admin user or regulare
    let tt = new TanksTable();
    let logInHelper = new LogInHelper();
    let customerId = logInHelper.getLoggedInCustomerId();
    if (logInHelper.isAdmin()) {
      this.setState({ tanks: tt.getTanks() });
    }
    else {
      let tanks = tt.getCustomerTanks(customerId);
      if (tanks.length === 0) {
        this.setState({ customrtWithNoTanks: true });
      }
      this.setState({ tanks: tanks });
    }

    this.tanksReadingReady();

  }

  tanksReadingReady = () => {
    let lm = new TanksReading().getLevelMonitoring("name");
    if (lm) {
      this.setState({ lastTanksReadings: lm });
    }
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }

  renderCompressed() {
    let readings = getFilteredReadings(this.state.lastTanksReadings, this.state.filterStr);
    return renderCompressedTanks(readings);
  }

  renderTanks() {

    let readings = getFilteredReadings(this.state.lastTanksReadings, this.state.filterStr);

    return renderTanks(readings, false);
  }


  renderAlerts = () => {

    // disabled for now
    if (true) {
      return null;
    }
    let nh = new NotificationHelper();

    let levelAlerts = nh.getLevelNotifications();
    let hearBeatAlerts = nh.getHeartBeatNotification();

    if (levelAlerts.length === 0 && hearBeatAlerts.length === 0) {
      return null;
    }

    return (
      <Col xs={12} onClick={() => this.props.history.push(`alerts`)}>
        <StatsCard
          statsText={
            <div style={{ animation: 'scale 0.5s linear infinite alternate', color: 'red' }}>
              <i className="pe-7s-gleam text-danger">Alerts</i>
            </div>}
          statsValue={<div>
            <br />
            {levelAlertView(levelAlerts)}
            {heartBeatView(hearBeatAlerts)}
          </div>}
          statsIcon={<i className="fa fa-clock-o" />}
          statsIconText="Click for more info"
        />
      </Col>
    );
  }

  isAlarmAlerted = () => {
    let isAlerted = false;
    let nh = new NotificationHelper();

    let levelAlerts = nh.getLevelNotifications();
    let hearBeatAlerts = nh.getHeartBeatNotification();

    if (levelAlerts.length === 0 && hearBeatAlerts.length === 0) {
      isAlerted = false;
    } else {
      isAlerted = true;
    }

    return isAlerted;
  }

  render() {
    if (this.state.tanks.length === 0) {
      return (
        <div className="content" style={{ backgroundColor: getBackgroundColor(), height: '120%' }}>
          <Card
            hCenter={true}
            title="Loading ..."
            content={
              <center>
                <Spinner />
                {this.state.customrtWithNoTanks === true &&
                  <font color='red' size='22'>
                    <b>
                      No Tanks Defined for this customer, login with admin and add tank
                    </b>
                  </font>
                }
              </center>}
          />
        </div>
      );
    }

    let showIndicators = false;
    return (
      <div className="content" style={{ backgroundColor: getBackgroundColor() }}>
        {/*<center><img src={logo} alt="logo_image" style={{ height: '8.5%' }} /></center>
        <p />*/}
        <Grid fluid alig>
          {new LogInHelper().isAdmin() &&
            <Row>
              {renderFilterTanksCard(this)}
            </Row>
          }
          {showIndicators &&
            <Row>
              <Indicators fromDashboard={true} />
            </Row>
          }
          <Row>
            {this.renderAlerts()}
          </Row>
          {isMobile() ?
            ''
            :
            <Row style={{
              fontSize: '3rem', color: 'white', backgroundColor: '#0382c5', marginBottom: '1%', marginTop: '1%'
            }}>
              <Col xs='3'>
                <center>
                  <span style={{ marginLeft: '2vw' }}>
                    Tanque
                  </span>
                </center>
              </Col>
              <Col xs='4'>
                <center>
                  Ultima hora diferencia (%)
                </center>
              </Col>
              <Col xs='2'>
                <center>
                  Reservas
                </center>
              </Col>
              <Col xs='3'>
                <center>
                  Datos del tanque
                </center>
              </Col>
            </Row>
          }
          {/*
          <Row>
            <Col md={12}>
              <Card
                hCenter={true}
                title="Monitoring Tanks Is Active"
                content={<center><Spinner /></center>}
              />
            </Col>
          </Row>
 */}
          {/*<Row>
            <Col xs={12} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-server text-warning" />}
                statsText="Capacity"
                statsValue="105GB"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            <Col xs={12} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Revenue"
                statsValue="$1,345"
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText="Last day"
              />
            </Col>
            <Col xs={12} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Errors"
                statsValue="23"
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="In the last hour"
              />
            </Col>
            <Col xs={12} sm={6}>
              <StatsCard
                bigIcon={<i className="fa fa-twitter text-info" />}
                statsText="Followers"
                statsValue="+45"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            
            <Col md={4}>
              <Card
                statsIcon="fa fa-clock-o"
                title="Email Statistics"
                category="Last Campaign Performance"
                stats="Campaign sent 2 days ago"
                content={
                  <div
                    id="chartPreferences"
                    className="ct-chart ct-perfect-fourth"
                  >
                    <ChartistGraph data={dataPie} type="Pie" />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendPie)}</div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Card
                id="chartActivity"
                title="2014 Sales"
                category="All products including Taxes"
                stats="Data information certified"
                statsIcon="fa fa-check"
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={dataBar}
                      type="Bar"
                      options={optionsBar}
                      responsiveOptions={responsiveBar}
                    />
                  </div>
                }
                legend={
                  <div className="legend">{this.createLegend(legendBar)}</div>
                }
              />
            </Col>

            <Col md={6}>
              <Card
                title="Tasks"
                category="Backend development"
                stats="Updated 3 minutes ago"
                statsIcon="fa fa-history"
                content={
                  <div className="table-full-width">
                    <table className="table">
                      <Tasks />
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>
              
          </Row>*/}
        </Grid>

        <Row>
          {this.renderCompressed()}
        </Row>
        {/* <Row>
            {this.renderTanks()}
          </Row> */}
        <Row>
          <Graph fromDashboard={true} />
        </Row>
        <Row>
          <Data fromDashboard={true} />
        </Row>
        <Row>
          <Locations filterStr={this.state.filterStr} fromDashboard={true} />
        </Row>
        <Row>
          <AlarmsManager isAlerted={this.isAlarmAlerted()} />
        </Row>
      </div >
    );
  }
}

export default Dashboard;
