import React from "react";
// import { DropdownButton, MenuItem, Checkbox, input, InputGroup, FormControl } from "react-bootstrap";
import TanksTable from "../../app/db/TanksTable";
import { LogInHelper } from "../../app/logInHelper";
// import { getButtonStyle } from '../../app/adminHelper'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';


export default class MultiTanksSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTankName: null
        };

        let customerId = new LogInHelper().getLoggedInCustomerId();
        this.tanks = new TanksTable().getCustomerTanks(customerId);
    }

    tankSelected = (index) => {

        this.setState({ selectedTankName: this.tanks[index].name });
        this.props.tankSelected && this.props.tankSelected(this.tanks[index]);
    }

    getTankName = () => {
        if (this.state.selectedTankName === null) { // not set yet
            return this.props.getSelectedTank().name
        }
        else {
            return this.state.selectedTankName
        }
    }

    handleChange = (selected) =>
        this.props.onTankSelected(selected)

    render() {
        // let tankName = this.getTankName()
        // return (
        //     <DropdownButton title={"Tank - " + tankName} bsStyle="primary"
        //         style={getButtonStyle()}
        //         id="bg-nested-dropdown">
        //         {
        //             this.tanks.map((tank, index) => {
        //                 return (
        //                     <MenuItem eventKey={index} key={index} onClick={() => this.tankSelected(index)}>
        //                         {tank.name}
        //                     </MenuItem>
        //                 )
        //             })
        //         }
        //     </DropdownButton>
        // )
        var defaultTanks = this.props.setSelectedTanks();
        var tanks = [];
        this.tanks.map((tank, index) => {
            tanks.push({ value: tank.name, label: tank.name })
            return null
        })
        var animatedComponents = makeAnimated();
        return <Select isMulti
            defaultValue={defaultTanks}
            components={animatedComponents}
            options={tanks}
            onChange={this.handleChange}></Select>
    }
}