import React from "react";
import { Modal, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { AppLogger } from "../../app/Logger";
import CustomersTable from "../../app/db/CustomersTable";
import { getPrimaryTextColor } from 'app/adminHelper'
import { translate } from 'app/lang/lang.js'
import { industries } from 'app/lang/dic.js'

export class CustomerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customerName: "", hideButtons: false, formMessage: null,
            mailingList: [],
            industry: props.isManagement ? "management" : industries[0]
        };

        this.style = { 'color': getPrimaryTextColor() };

    }

    restCallBack = (message) => {
        if (message) {
            this.setState({ hideButtons: false, formMessage: message });
        }
        else {
            this.props.onHide();
            this.setState({ hideButtons: false, formMessage: '' });
        }
    }

    addNewCustomer() {
        if (this.validatCustomer() === 'error') {
            this.setState({ formMessage: 'Invalid customer name' });
            return;
        }
        this.setState({ hideButtons: true, formMessage: 'Adding New Customer...' });
        let customrtTable = new CustomersTable();
        customrtTable.addNewCustomer(
            this.state.customerName,
            this.props.isManagement ? "management" : this.state.industry,
            this.state.mailingList,
            this.restCallBack);
    }

    updateCustomer() {
        console.log(AppLogger("updating " + this.props.customer.name));
        let customrtTable = new CustomersTable();
        customrtTable.updateCustomer(this.props.customer, this.restCallBack);
    }

    validateMail(mail) {
        var re = /\S+@\S+\.\S+/;
        if (mail && mail.length !== 0 && re.test(mail) === false) {
            return 'error';
        }

        return 'success';
    }

    validatCustomer() {
        if (this.state.customerName.length > 2) {
            return 'success'
        }
        return 'error'
    }

    deleteMail = (index) => {
        let tmpMailingList = this.state.mailingList;
        if (this.props.customer) {
            tmpMailingList = this.props.customer.mailing_list;
        }
        tmpMailingList.splice(index, 1);
        this.setState({ mailingList: tmpMailingList });
        if (this.props.customer) {
            this.props.customer.mailing_list = tmpMailingList;
        }
    }

    addMail = () => {
        if (this.validateMail(this.state.mailAddrees) === 'error') {
            this.setState({ formMessage: "Mail is not valid" });
        }
        else {
            let tmpMailingList = this.state.mailingList;
            tmpMailingList.push(this.state.mailAddrees);
            this.setState({ mailingList: tmpMailingList, formMessage: null });
            if (this.props.customer) {
                this.props.customer.mailing_list = tmpMailingList;
            }
        }
    }

    createMailForm = (mail, index) => {

        return (
            <div align="left" key={index}>
                <Button style={{ margin: '3px' }} value={index}
                    onClick={(e) => this.deleteMail(e.target.value)}>
                    Remove
                </Button>
                &nbsp; {mail}
            </div>
        );
    }

    renderFormCustomerFiled() {

        let mailingList = this.state.mailingList;
        if (this.props.customer) {
            mailingList = [...this.props.customer.mailing_list];
        }

        return (
            <div>
                {this.props.customer === null &&
                    <FormGroup controlId="formBasicText"
                        validationState={this.validatCustomer()}>
                        <center><ControlLabel style={this.style}>{translate('customerName')}</ControlLabel></center>
                        <FormControl
                            type="text"
                            placeholder={translate('customerName')}
                            onChange={(e) => { this.setState({ customerName: e.target.value, formMessage: null }) }}
                        />
                    </FormGroup>
                }
                {this.props.customer === null && this.props.isManagement !== true &&
                    <FormGroup controlId="formBasicText">
                        <center><ControlLabel style={this.style}>{translate('industry')}</ControlLabel></center>
                        <FormControl componentClass="select" onChange={(e) => this.setState({ industry: e.target.value })}>
                            {industries.map(function (name, index) {
                                return <option key={index} value={name}>
                                    {translate(name)}
                                </option>
                            })}
                        </FormControl>
                    </FormGroup>
                }
                {
                    mailingList.length > 0 && <center><ControlLabel style={this.style}>Alerts Mails</ControlLabel></center>
                }
                {
                    mailingList.map((mail, index) => this.createMailForm(mail, index))
                }
                <hr />
                <FormGroup controlId="formBasicText"
                    validationState={this.validateMail()}>
                    <center><ControlLabel style={this.style}>{translate('mailAddress')}</ControlLabel></center>
                    <FormControl
                        type="email"
                        placeholder={translate('mailAddress')}
                        onChange={(e) => { this.setState({ mailAddrees: e.target.value, formMessage: null }) }}
                    />
                </FormGroup>
                <center><Button
                    onClick={(index) => this.addMail(index)}>{"Add Alerts Mail"}
                </Button></center>
            </div>
        )
    }

    createForm() {

        return (
            <form>
                {this.renderFormCustomerFiled()}
                <center><div>{this.state.formMessage}</div></center>
            </form>
        );
    }

    deleteCustomer() {
        console.log(AppLogger("deleteCustomer " + this.props.customer.id));

        let customrtTable = new CustomersTable();
        customrtTable.deleteCustomer(this.props.customer.id);
        this.props.onHide();
    }

    renderButtons() {
        if (this.state.hideButtons === true) {
            return null;
        }
        return (
            <div>
                <Button onClick={this.props.onHide}>{translate('cancel')}</Button>
                {this.props.customer == null ?
                    <Button onClick={() => this.addNewCustomer()}>{translate('add')}</Button> :
                    <Button onClick={() => this.updateCustomer()}>{translate('update')}</Button>}
            </div>
        );
    }

    render() {
        let addnewCustomerText = translate('addNewCustomer');
        return (
            <Modal {...this.props} bsSize="small" aria-labelledby="contained-modal-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm" style={this.style}>
                        {this.props.customer ?
                            "Manage Customer - " + this.props.customer.name :
                            addnewCustomerText}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {this.createForm()}
                </Modal.Footer>
                <Modal.Footer>
                    {this.renderButtons()}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default CustomerForm;