import { Cookies } from 'react-cookie';
import UsersTable from './db/UsersTable';

export class LogInHelper {

    constructor() {
        this.logOutStr = 'logedOut';
        this.userKey = 'user';
    }

    isAdmin() {
        let usersTable = new UsersTable();

        let user = usersTable.getUser(this.getLogedInUser());

        if (user && user.role === 'admin') {
            return true;
        }

        return false;
    }

    getLogedInUser() {
        let user = "";
        try{
            // test local storage
            window.localStorage.setItem("just","just");
            user = window.localStorage.getItem(this.userKey);
        }
        catch(e){
            let cookie = new Cookies();
            user = cookie.get(this.userKey);
        }


        if (user && user !== this.logOutStr) {
            return user;
        }
        return "";
    }

    isLogIn() {
        let user = "";
        try {
            // test local storage
            window.localStorage.setItem("just","just");
            console.log("isLogIn getting user from local storage");
            user = window.localStorage.getItem(this.userKey);
        }
        catch (e){
            console.log("isLogIn getting user from cookies");
            let cookie = new Cookies();
            user = cookie.get(this.userKey);
        }
        console.log("isLogIn user is " + user);

        if (user && user !== this.logOutStr) {
            return true;
        }
        return false;
    }

    logOut() {
        try {
            window.localStorage.setItem(this.userKey, this.logOutStr);
        }
        catch (e){
            let cookie = new Cookies();
            cookie.set(this.userKey, this.logOutStr);
        }
    }

    getLoggedInCustomerId() {
        let usersTable = new UsersTable();

        let user = usersTable.getUser(this.getLogedInUser());

        if (user) {
            return user.customer_id;
        }
        else if (this.isLogIn()) {
            return "admin";
        }
        else {
            return "";
        }
    }
}

export default LogInHelper;