import React from 'react';
import Chart from 'react-google-charts';

export function Gauge(props) {

    return (
        <center style={{ height: props.height }}>
            <center><h3 style={{ color: getColor() }}><b>{props.value} PSI</b></h3></center>
            <Chart
                height={props.height * .8}
                chartType="Gauge"
                loader={<div>Loading Chart</div>}
                data={getData(props.value)}
                options={getOptions()}
                rootProps={{ 'data-testid': '1' }}
            />
        </center>
    )

    function getData(value) {
        return (
            [
                ['Label', 'Value'],
                ['PSI', value],
            ]
        )
    }

    function getOptions() {
        return ({
            redFrom: 50,
            redTo: 60,
            yellowFrom: 40,
            yellowTo: 50,
            greenFrom: 30,
            greenTo: 40,
            minorTicks: 5,
            majorTicks: [0, 10, 20, 30, 40, 50, 60, 70, 80],
            max: 80,
            forceIFrame: true,
        })
    }

    function getColor() {
        return props.asAlerts ? 'red' : '#00A4D5'
    }
}