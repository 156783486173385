import React from "react";
import ReactDOM from "react-dom";

import { HashRouter, Route, Switch } from "react-router-dom";
import indexRoutes from "routes/index.jsx";
import "./assets/css/animate.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import packageJson from '../package.json';
import { setVersion } from "./../src/app/adminHelper"
import * as sw from './service-worker';
import serviceWorkerConfig from './serviceWorkerConfig'

ReactDOM.render(
  <HashRouter>
    <Switch>
      {setVersion(packageJson.version)}
      {console.log("Version - " + packageJson.version)}
      {indexRoutes.map((prop, key) => {
        return <Route to={prop.path} component={prop.component} key={key} />;
      })}
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
sw.register(new serviceWorkerConfig());
