import React from 'react';

import TanksTable from "../../app/db/TanksTable.jsx"
import CustomersTable from "../../app/db/CustomersTable.jsx"
import { LogInHelper } from "app/logInHelper";
import { Card } from "components/Card/Card.jsx";
import { translate } from "../../app/lang/lang";
import { getDetailedFormattedTime } from "app/adminHelper.jsx"
import { Col, FormControl, Table, Row } from "react-bootstrap";
import NotificationHelper from "../../app/notificationHelper"
import { getPrimaryTextColor } from '../../app/adminHelper.jsx';
import { AppLogger } from '../../app/Logger.jsx';
import { getTankComponent } from '../../components/App/TankTypes.js';
import CompreddedTankRow from './CompressedTankRow.jsx';

export function renderCompressedTanks(readings) {

    let numberOfRows = readings.length;
    let rows = [];

    for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
        rows.push(<CompreddedTankRow key={rowIndex} reading={readings[rowIndex]} />)
    }
    return rows;
}

export function renderTanks(readings) {
    let width = 6;
    if (readings.length === 1) {
        width = 12;
    }

    AppLogger("renderTanks - readings len " + readings.length);

    let numberOfRows = Math.floor((readings.length + 1) / 2);
    let rows = [];
    let tankIndex = 0;
    for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
        let tanks = getTwoTanks(readings, tankIndex, width);
        tankIndex += tanks.length;
        rows.push(
            <Row key={rowIndex}>
                {tanks}
            </Row>
        )
    }
    return rows;
}
function getTwoTanks(readings, tankIndex, width) {
    let tanks = [];
    if (tankIndex < readings.length) {
        tanks.push(
            getTank(readings[tankIndex], tankIndex, width)
        )
    }
    if (tankIndex + 1 < readings.length) {
        tanks.push(
            getTank(readings[tankIndex + 1], tankIndex + 1, width)
        )
    }
    return tanks;
}

function getTank(reading, index, width) {
    let tt = new TanksTable();
    let tank = tt.getTank(reading.imei);
    if (tank === null) {
        return null;
    }
    let tankName = tank.name;

    //let volume = parseInt(tank.capacity * reading.actual_level / 100, 10) + " L";
    let volumeInL = parseInt(tank.capacity * reading.actual_level / 100, 10);
    let volumeInGal = (Math.floor(volumeInL * 0.264172)).toLocaleString() + " Galones";
    let nh = new NotificationHelper();
    let levelAlerts = nh.getLevelNotificationsOnTank(tank);
    //let hearBeatAlerts = nh.getHeartBeatNotificationForTank(tank);
    //let asAlert = (levelAlerts.length !==0 || hearBeatAlerts.length !==0)?true:false
    let asAlert = (levelAlerts.length !== 0) ? true : false
    let titleColor = getPrimaryTextColor();
    if (asAlert) {
        titleColor = 'red'
    }

    let isCompact = false;

    return (
        <Col xs={12} sm={width} key={index} >
            <Card
                hCenter={true}
                id="chartHours"
                title={tankName}
                color={titleColor}
                stats={isCompact ? null : renderTanksParams(tank, reading, nh)}
                content={getTankComponent(tank, reading.actual_level, volumeInGal, asAlert)}
            />
        </Col>
    );
}

export function isTankFiltered(tank, filterStr) {
    if (filterStr !== "") {
        let search = filterStr.toLowerCase();
        let tt = new TanksTable();
        let customer = tt.getCustomerId(tank.imei);
        let customerName = new CustomersTable().getCustomerName(customer);

        if (tank.imei.search(search) === -1 &&
            tank.name.toLowerCase().search(search) === -1 &&
            customerName.toLowerCase().search(search) === -1 &&
            tank.capacity.toString().search(search) === -1 &&
            tank.liquid_type.toLowerCase().search(search) === -1)
            return true;
    }
    return false;
}

function getCustomer(tank) {
    if (new LogInHelper().isAdmin()) {
        return new CustomersTable().getCustomerName(tank.customer);
    }
    else {
        return null;
    }
}

function renderTableRow(t1, t2) {
    return (
        <tr>
            <td><center>{t1}</center></td>
            <td><center>{t2}</center></td>
        </tr>
    )
}

function renderCompressedTableRow(t1, t2, t3, t4) {
    return (
        <tr>
            <td style={{ fontWeight: 'bold' }}><center>{t1}</center></td>
            <td><center>{t2}</center></td>
            <td style={{ fontWeight: 'bold' }}><center>{t3}</center></td>
            <td><center>{t4}</center></td>
        </tr>
    )
}

function renderTableRowForCompressedAlert(t1, t2, color1, t3, t4, color2) {
    return (
        <tr>
            <td style={{ color: color1, fontWeight: 'bold' }}><center>{t1}</center></td>
            <td style={{ color: color1 }}><center>{t2}</center></td>
            <td style={{ color: color2, fontWeight: 'bold' }}><center>{t3}</center></td>
            <td style={{ color: color2 }}><center>{t4}</center></td>
        </tr>
    )
}

function renderTableRowForAlert(t1, t2, color) {
    return (
        <tr>
            <td style={{ color: color }}><center>{t1}</center></td>
            <td style={{ color: color }}><center>{t2}</center></td>
        </tr>
    )
}

export function renderTanksParams(tank, readings, isCompressed, margintop) {
    if (readings === null) {
        return null;
    }

    let nh = new NotificationHelper();

    let isAdmin = new LogInHelper().isAdmin();
    let levelAlerts = nh.getLevelNotificationsOnTank(tank);
    let hearBeatAlerts = nh.getHeartBeatNotificationForTank(tank);
    let capacityInGal = ((Math.floor(tank.capacity * 0.264172))).toLocaleString();
    let volumeInL = tank.capacity * readings.actual_level / 100;
    let volumeInGal = ((Math.floor(volumeInL * 0.264172))).toLocaleString();

    var toReturn;
    if (!isCompressed) {
        toReturn = (
            <div>
                <Table striped bordered condensed>
                    <tbody>
                        {renderTableRow(translate("tankName"), tank.name)}
                        {renderTableRow(translate("buildingName"), tank.building)}
                        {getCustomer(tank) !== null &&
                            renderTableRow("Customer", getCustomer(tank))
                        }
                        {renderTableRow(translate("capacity"), capacityInGal + " Galones")}
                        {readings !== null &&
                            renderTableRow(translate("volume"), volumeInGal + " Galones")
                        }
                        {readings !== null &&
                            renderTableRow(translate("leftInTank"), readings.actual_level + "%")
                        }
                        {readings !== null &&
                            renderTableRow(translate("lastUpdate"), getDetailedFormattedTime(readings.server_time))
                        }
                        {renderTableRow(translate("liquid"), translate(tank.liquid_type))}
                        {isAdmin && renderTableRow(translate("sensorLength"), tank.sensor_type + "M")}
                        {renderTableRowForAlert(translate("levelAlert"), levelAlerts.length > 0 ? translate("Level Below") + " " + levelAlerts[0].level + "%" : translate("Level OK"), levelAlerts.length > 0 ? 'red' : 'black')}
                        {renderTableRowForAlert(translate("heartbeat"), hearBeatAlerts.length > 0 ? translate("Not Connected") : translate("Connected"), hearBeatAlerts.length > 0 ? 'red' : 'black')}
                    </tbody>
                </Table>
            </div>
        )
    } else {
        toReturn = (
            <div style={{ backgroundColor: 'white', marginTop: margintop }}>
                <Table striped bordered condensed hover>
                    <tbody>
                        {renderCompressedTableRow(translate("tankName"), tank.name, translate("buildingName"), tank.building)}
                        {getCustomer(tank) !== null &&
                            renderCompressedTableRow("Customer", getCustomer(tank), translate("capacity"), capacityInGal + " Galones")
                        }
                        {readings !== null &&
                            renderCompressedTableRow(translate("volume"), volumeInGal + " Galones", translate("leftInTank"), readings.actual_level + "%")
                        }
                        {readings !== null &&
                            renderCompressedTableRow(translate("lastUpdate"), getDetailedFormattedTime(readings.server_time), translate("liquid"), translate(tank.liquid_type))
                        }
                        {renderTableRowForCompressedAlert(translate("levelAlert"), levelAlerts.length > 0 ? translate("Level Below") + " " + levelAlerts[0].level + "%" : translate("Level OK"), levelAlerts.length > 0 ? 'red' : 'black', isAdmin && translate("sensorLength"), isAdmin && (tank.sensor_type + "M"), 'black')}
                        {/* {renderTableRowForAlert(translate("heartbeat"), hearBeatAlerts.length > 0 ? translate("Not Connected") : translate("Connected"), hearBeatAlerts.length > 0 ? 'red' : 'black')} */}
                    </tbody>
                </Table >
            </div >
        )
    }
    return toReturn;
}

export function renderFilterTanksCard(caller) {
    //let logInHelper = new LogInHelper();
    ///if (logInHelper.isAdmin() === false) {
    //    return null
    //}

    return (
        <Col md={6}>
            <Card
                id={"filterTanks"}
                title="Filter Tanks"
                hCenter={true}
                content={
                    <FormControl
                        type="text"
                        value={caller.state.value}
                        placeholder="Tank / Customer / Capacity / IMEI"
                        onChange={(e) => caller.setState({ filterStr: e.target.value })}
                    />
                }
            />
        </Col>
    );
}