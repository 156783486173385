import React, { Component } from "react";
import RangeHelper from "./../Dashboard/RangeHelper";
import DateAndTimeModal from "./DateAndTimeModal";
import { ButtonToolbar, Button } from "react-bootstrap";
import { AppLogger } from "../../app/Logger";
import TanksSelection from "./../../views/Admin/TanksSelection"
import MultiTanksSelection from "./../../views/Admin/MultiTanksSelection"
import { getButtonStyle } from '../../app/adminHelper'

class RangeView extends Component {
    constructor(props) {
        super(props);
        this.state = { showDateAndTimeModal: false };
    }

    rangeSelected = (value) => {
        console.log(AppLogger("rangeSelected " + value));

        let rangeInHour = new RangeHelper().getRangeInHour(value);
        if (rangeInHour === null) {
            this.setState({ showDateAndTimeModal: true });
        }
        else {
            this.props.hoursSelected(rangeInHour, value);
        }
    }
    getDate = (date, time) => {
        let hour = parseInt(time.substring(0, 2), 10);
        let minutes = parseInt(time.substring(3, 5), 10);
        date.setHours(hour);
        date.setMinutes(minutes);
        return date;
    }

    dateAndTimeModalClose = (startDate = null, endDate = null, startTime = null, endTime = null) => {
        if (startDate !== null && endDate !== null &&
            startTime !== null && endTime !== null) {
            let start = this.getDate(startDate, startTime);
            let end = this.getDate(endDate, endTime);
            this.props.rangeSelected(start.getTime(), end.getTime());
        }
        else {
            this.props.hoursSelected(6, 0);
        }

        this.setState({ showDateAndTimeModal: false });
    }

    render() {
        let ranges = new RangeHelper().getRanges();
        let paddingMulti = this.props.isMulti ? '10px' : '60px';
        return (
            <div style={{ paddingRight: '10px' }}>
                <ButtonToolbar style={{ paddingLeft: '22px', opacity: '100%' }}>
                    {this.props.isMulti ?
                        <MultiTanksSelection tankSelected={this.props.tankSelected}
                            getSelectedTank={this.props.getSelectedTank}
                            onTankSelected={this.props.onTankSelected}
                            setSelectedTanks={this.props.setSelectedTanks} />
                        :
                        <TanksSelection tankSelected={this.props.tankSelected}
                            getSelectedTank={this.props.getSelectedTank}
                            // tankSelected={this.props.tankSelected}
                            setSelectedTanks={this.props.setSelectedTanks}

                        />}
                    <ButtonToolbar style={{ float: 'center', paddingRight: '15px', paddingTop: paddingMulti }}>
                        {
                            ranges.map((range, index) => {
                                return (
                                    <Button
                                        bsStyle="primary"
                                        value={index}
                                        style={Object.assign(getButtonStyle(), Number(index) === 5 ? { float: 'right' } : {})}
                                        key={index}
                                        onClick={() => this.rangeSelected(index)}>{range}</Button>
                                )
                            })
                        }
                    </ButtonToolbar>
                </ButtonToolbar>
                <DateAndTimeModal show={this.state.showDateAndTimeModal} onHide={this.dateAndTimeModalClose} />
            </div >);
    }
}

export default RangeView;
