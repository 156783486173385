import React, { Component } from "react";
import { isIos, isAndroid, isMobile, getPrimaryTextColor } from "../../app/adminHelper";
import { Card } from "../../components/Card/Card"
import { Col } from "react-bootstrap";
import installLogoAndroid from "assets/img/3dots.png";
import installLogoIos from "assets/img/iosshare.png";
import logo from "assets/img/orbitBanner3000.png";


class Install extends Component {

    componentDidMount() {
        //update reading timer
    }

    renderInstructions = () => {
        let style = {
            color: 'black',
            height: '20%',
            borderRadius: '6px',
            padding: '2%',
            paddingLeft: '5%',
            paddingRight: '5%'
        };

        if (isIos() || isAndroid()) {
            let installIcon = installLogoAndroid;
            if (isIos()){
                installIcon = installLogoIos;
            }
            return (
                <div>
                    <h3 style={style}>
                        <center><b>
                            <div>
                                Just tap
                                <img src={installIcon} alt="logo_image" style={{ height: '20%', margin: '3%' }} />
                                then
                            </div>
                            <div>
                                'Add To Home Screen'
                            </div>
                        </b></center>
                    </h3>
                </div>
            )
        }
        /*
                if (isIos()) {
                    return (
                        <center><div className="logo" style={{ backgroundColor: 'white', height: '15%' }}>
                            <img src={addToHomeScreenIos} alt="logo_image" style={{ marginTop: '5%', width: '90%' }} />
                        </div></center>
                    )
                }
                else {
                    return (
                        <center ><div className="logo" style={{ backgroundColor: 'white', height: '15%', marginBottom: '30%' }}>
                            <img src={addToHomeScreenAndroid} alt="logo_image" style={{ marginTop: '5%', width: '90%' }} />
                        </div></center>
                    )
                }
                */
    }



    render() {
        return (
            <Col xs={12} >
                <Card
                    hCenter={true}
                    id="chartHours"
                    title={
                        <div>
                            {isMobile() === false &&
                                <div className="logo" style={{ backgroundColor: 'white', height: '11.2%' }}>
                                    <img src={logo} alt="logo_image" style={{ marginTop: '5%', height: '80%' }} />
                                </div>
                            }
                        </div>
                    }
                    color={getPrimaryTextColor()}
                    content={
                        <div style={{ color: getPrimaryTextColor() }}>
                            {isMobile() === true &&
                                <center>
                                    <h4><b>Install ORBIT application</b></h4>
                                    <h5><b>on your home screen</b></h5>
                                    <h5><b>for quick and easy access.</b></h5>
                                </center>
                            }
                            {isMobile() === false &&
                                <center>
                                    <h4><b>Install ORBIT application</b></h4>
                                    <h5><b>You can install the application on your mobile device</b></h5>
                                    <h5><b>Just go to www.goiwater.com on your mobile device and follow the instaructions</b></h5>
                                </center>
                            }

                        </div>
                    }
                    stats={this.renderInstructions()}
                />
            </Col>
        )
    }
}

export default Install;
