import React, { Component } from "react";
import { Col, Table, Button, ButtonToolbar } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import TanksTable from "../../app/db/TanksTable"
import { getFormattedTime } from "app/adminHelper.jsx"
import { makePdf } from "./PdfMaker"
import { isMobile, isIos, getButtonStyle } from '../../app/adminHelper'

class TableList extends Component {

  constructor(props) {
    super(props);
    this.arrayForTable = [];
    this.arrayForExcel = [[]];
    this.arrayForPdf = [[]];
    this.tanksNames = [];
    this.state = { excelTableReady: false };
  }

  componentDidMount = () => {
    import('./ExcelTable').then(ExcelTable => {
      this.ExcelTable = ExcelTable;
      this.setState({ excelTableReady: true });
      console.log("excelTable Ready");
    })
  }

  getHeader = () => {
    return ["Left in Tank (%)", "Height", "Volume", "Time"];
  }

  createData = () => {
    this.arrayForExcel = [[]];
    this.arrayForPdf = [[]];
    this.arrayForTable = [];
    this.tanksNames = [];
    let tt = new TanksTable();
    for (let index in this.props.rangeReadings) {
      let tank = tt.getTank(this.props.rangeReadings[index].imei);
      let tankName = tank.name;
      this.tanksNames.push(tankName);
      this.arrayForExcel[index] = [];
      this.arrayForPdf[index] = [];
      this.arrayForPdf[index].push([tankName, 'Time', 'Left In Tank', 'Volume']);
      for (let readings in this.props.rangeReadings[index].levels) {
        let time = getFormattedTime(this.props.rangeReadings[index].times[readings]);
        let level = this.props.rangeReadings[index].levels[readings];
        let liquidHeightIמMeters = (tank.high / 10000 * level).toFixed(2);
        let sample = this.props.rangeReadings[index].samples[readings];
        let vol = parseInt(level * tank.capacity / 100, 10);
        let volInGal = Math.floor(vol * 0.264172)
        let formattedVol = volInGal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let row = [
          level + " %",
          liquidHeightIמMeters + " M",
          formattedVol + " gal",
          time];
        this.arrayForTable.unshift(row);
        this.arrayForExcel[index].unshift({ time: time, level: level + " %", vol: formattedVol + " gal", samples: sample });
        this.arrayForPdf[index].unshift(['', time, level + " %", formattedVol + " gal"]);
      }
      if (this.props.rangeReadings[index].levels.length === 0) {
        // tank without readings history
        let row = [
          tankName,
          "No History For This Tank",
          "!!!!!!!",
          "!!!!!!!"];
        this.arrayForTable.push(row);
      }
    }
  }

  creatPdfDocDefinition = () => {
    let array = [];
    for (let index in this.arrayForPdf) {
      let pb = { text: '', pageBreak: 'after', style: 'subheader' };

      let obj = {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: this.arrayForPdf[index]
        }
      };
      array.push(obj);
      array.push(pb);
    }

    return array;
  }

  exportToPdf = (exportOrPrint) => {
    makePdf(exportOrPrint, this.creatPdfDocDefinition());
  }

  renderRangeButtons = () => {
    return (
      <div>
        {this.props.rangeButtons}
      </div>
    )
  }
  renderExport = () => {
    console.log("renderExport");
    return (
      <div>
        {isIos() === false &&
          <div style={{ overflow: 'hidden' }}>
            {isMobile() === true &&
              <hr />
            }
            <ButtonToolbar style={{ float: 'right', paddingRight: '20px', paddingLeft: '5px' }}>
              <this.ExcelTable.default button={<Button active className="pe-7s-news-paper" bsStyle="primary" style={getButtonStyle()} >
                <span> Export To Excel</span>
              </Button>} data={this.arrayForExcel} tanksNames={this.tanksNames} />
              <Button active className="pe-7s-file" bsStyle="primary" style={getButtonStyle()} onClick={() => this.exportToPdf('export')}>
                <span> Export To PDF</span>
              </Button>
              {isMobile() === false &&
                <Button className="pe-7s-print" bsStyle="primary" style={getButtonStyle()} onClick={() => this.exportToPdf('print')}>
                  <span> Print</span>
                </Button>
              }
            </ButtonToolbar>
          </div>
        }
      </div>
    );
  }

  render() {

    if (this.state.excelTableReady === false) {
      return null;
    }
    console.log("render TableList ");

    this.createData();
    let header = this.getHeader();
    return (
      <Col md={12} style={{
        paddingRight: 0,
        paddingLeft: 0
      }}>
        <Card
          opacity={!this.props.fromDashboard}
          // title={this.props.title}
          category={this.props.category}
          hCenter={true}
          ctTableFullWidth
          title={this.renderExport()}
          stats={this.renderRangeButtons()}
          content={
            <div style={{ height: '50%', overflowY: 'auto', width: '96%', marginLeft: '2%' }}>
              <Table striped hover >
                <thead >
                  <tr>
                    {header.map((prop, key) => {
                      return <th key={key} style={{ textAlign: 'center' }}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody style={{ overflow: 'auto', }}>
                  {this.arrayForTable.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((prop, key) => {
                          return <td key={key} style={{ textAlign: 'center' }}>{prop}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          }
        />
      </Col>
    );
  }
}

export default TableList;
