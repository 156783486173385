import React, { Component } from 'react';
import okSound from 'assets/sounds/todosLosTanques.mp3';
import alarmSound from 'assets/sounds/Alarm.mp3';

class AlarmsManager extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAlarmOn: false,
            isAlerted: props.isAlerted,
            isSuccessPlayed: false,
            isFirstLoad: true,
        };
        this.audioRef = React.createRef();
        this.alarmDuration = 30000; // 30 seconds in milliseconds
        this.intervalBetweenAlarms = 180000; // 3 minutes in milliseconds
        this.successDuration = 3000; // 3 seconds in milliseconds
        this.delayForUiMatch = 52000; // 52 seconds in milliseconds
    }

    handleStartAlarm = () => {
        if (!this.state.isAlarmOn) {
            this.playAlarm();
            this.setState({ isAlarmOn: true });
            this.setState({ isSuccessPlayed: false });
            this.intervalId = setInterval(this.playAlarm, this.intervalBetweenAlarms);
        }
    };

    handleStopAlarm = () => {
        if (this.state.isAlarmOn) {
            this.stopAlarm();
            this.setState({ isAlarmOn: false });
            clearInterval(this.intervalId);
        } else if (!this.state.isSuccessPlayed) {
            this.playAllGood();
            this.setState({ isSuccessPlayed: true });
        }
    };

    playAllGood = () => {
        // Play the All good sound using the <audio> element
        if (this.audioRef.current) {
            setTimeout(() => {
                this.audioRef.current.play();
            }, this.state.isFirstLoad ? 0 : this.delayForUiMatch);

            setTimeout(() => {
                this.stopAlarm();
            }, this.successDuration + (this.state.isFirstLoad ? 0 : this.delayForUiMatch));

            this.setState({ isFirstLoad: false });
        }
    };

    playAlarm = () => {
        // Play the alarm sound using the <audio> element
        if (this.audioRef.current) {
            setTimeout(() => {
                this.audioRef.current.play();
            }, this.state.isFirstLoad ? 0 : this.delayForUiMatch);


            // Stop the audio after X seconds
            setTimeout(() => {
                this.stopAlarm();
            }, this.alarmDuration + (this.state.isFirstLoad ? 0 : this.delayForUiMatch));

            this.setState({ isFirstLoad: false });
        }
    };

    stopAlarm = () => {
        // Stop the alarm sound using the <audio> element
        if (this.audioRef.current) {
            this.audioRef.current.pause();
            this.audioRef.current.currentTime = 0;
        }
    };

    componentDidUpdate() {
        if (this.props.isAlerted) {
            this.handleStartAlarm();
        } else {
            this.handleStopAlarm();
        }
    }

    render() {
        let audioElement = this.props.isAlerted ?
            <audio loop ref={this.audioRef} src={alarmSound} type="audio/mpeg" />
            :
            <audio ref={this.audioRef} src={okSound} type="audio/mpeg" />
        return audioElement;
        // return (
        //     <div>
        //         <h1>React Alarm</h1>
        //         <button onClick={this.handleStartAlarm}>Start Alarm</button>
        //         <button onClick={this.handleStopAlarm}>Stop Alarm</button>

        //         <audio loop ref={this.audioRef} src="http://codeskulptor-demos.commondatastorage.googleapis.com/descent/gotitem.mp3" type="audio/mpeg" />
        //     </div>
        // );
    }
}

export default AlarmsManager;