import React from "react";
import { Modal, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { AppLogger } from "../../app/Logger";
import TanksTable from "../../app/db/TanksTable";
//import { translate } from "../../app/lang/lang";

export class AlertsForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            at_level: -1,
            alert: 0,
            subject: "",
            body: "",

            numberOfAlerts: 0,
            statusMessage: ""
        };
    }

    createForm() {
        return (
            <form>
                <FormGroup controlId="formBasicText">
                    <ControlLabel>Alert Level</ControlLabel>
                    <FormControl
                        type="text"
                        placeholder={"Alert Level in %"}
                        onChange={(e) => this.setState({ at_level: parseInt(e.target.value, 10), statusMessage:"" })}
                    />
                    <ControlLabel>Mail Subject</ControlLabel>
                    <FormControl
                        type="text"
                        placeholder={"Mail Subject"}
                        onChange={(e) => this.setState({ subject: e.target.value , statusMessage:""})}
                    />
                    <ControlLabel>Mail Body</ControlLabel>
                    <FormControl
                        type="text"
                        placeholder={"Mail Body"}
                        onChange={(e) => this.setState({ body: e.target.value, statusMessage:"" })}
                    />
                </FormGroup>
                <Button bsStyle="success" style={{ margin: '3px' }} onClick={() => this.addAlert()}>Add New Alert</Button>
                {this.state.statusMessage}
            </form>
        );
    }

    addAlert = () => {
        let validate = this.validate()
        if (validate !== 'ok') {
            this.setState({ statusMessage: validate });
            return;
        }
        else {
            this.setState({ statusMessage: "" });
        }

        // find empty alert id
        let alertId = 0;
        for (let index = 1; index < 2000; index++) {
            for (let alertIndex in this.props.level_alerts) {
                if (this.props.level_alerts[alertIndex].alert === index){
                    break;
                }
            }
            alertId = index;
        }

        let alert = {
            "alert": alertId,
            "at_level": this.state.at_level,
            "mail": {
                "subject": this.state.subject,
                "body": this.state.body
            }
        }
        this.props.tank.level_alerts.push(alert);
        this.setState({ numberOfAlerts: this.props.tank.level_alerts.length });
        this.updateAlerts();
    }


    getFormTitle() {
        if (this.props.tank) {
            return "Tanks Alerts - " + this.props.tank.name;
        }
        else {
            return "";
        }
    }

    delete = (index) => {
        this.props.tank.level_alerts.splice(index, 1);
        this.setState({ numberOfAlerts: this.props.tank.level_alerts.length });
        this.updateAlerts();
    }

    renderAlerts = () => {
        return (
            <div>
                {this.props.tank.level_alerts.length > 0 && <ControlLabel>Alerts</ControlLabel>}
                {this.props.tank.level_alerts.map((alert, index) =>
                    <div>
                        Level - {alert.at_level}% Subject - {alert.mail.subject} Body - {alert.mail.body}
                        <Button bsStyle="warning" style={{ margin: '3px' }} value={index}
                            onClick={(e) => this.delete(e.target.value)}>Delete</Button>
                    </div>
                )}
                {this.props.tank.level_alerts.length > 0 && <hr />}
            </div>
        );
    }

    validate = () => {
        if (this.state.at_level < 0 || this.state.at_level > 100) {
            return 'invalid level - must be a number 0-100';
        }
        // check if this we have alert with the same level
        for (let index in this.props.tank.level_alerts){
            if (this.props.tank.level_alerts[index].at_level === this.state.at_level){
                return "invalid level - tank allready has alert in level " + this.state.at_level + "%";
            }
        }

        if (this.state.body.length === 0) {
            return "invalid body - body is empty";
        }
        if (this.state.subject.length < 2) {
            return "invalid subject - subject is empty";
        }
        return 'ok';
    }

    updateAlerts = () => {
        let tt = new TanksTable();
        let customer = this.props.tank.customer;
        tt.updateTank(this.updateAlertsCallBack, this.props.tank, customer);
    }

    updateAlertsCallBack = (success) => {
        if (success){
            this.setState({statusMessage:"Updating alerts succedded"});
        }
        else{
            this.setState({statusMessage:"Updating alerts failed"});
        }
        //this.props.onHide && this.props.onHide();
    }

    render() {
        console.log(AppLogger( "render"));
        if (this.props.tank === null) {
            return null;
        }

        return (
            <Modal {...this.props} bsSize="lg" aria-labelledby="contained-modal-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm">
                        {this.getFormTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.renderAlerts()}
                    {this.createForm()}
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="warning" onClick={this.props.onHide}>Done</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AlertsForm;