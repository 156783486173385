import React, { Component } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

export class MapContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showingInfoWindow: false,
            activeMarker: {},
            selectedPlace: {},
            loaded: false
        };

        this.activeMarkerTitle = "";
    }

    componentDidMount() {
        var intervalId = setInterval(this.timer, 1000);
        // store intervalId in the state so it can be accessed later:
        this.setState({ intervalId: intervalId });
    }

    timer = () => {
        console.log("maps ready");
        clearInterval(this.state.intervalId);
        this.setState({ loaded: true });
    }

    onMarkerClick = (props, marker, e) => {
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onMouseOver = (props, marker, e) => {
        if (this.activeMarkerTitle === marker.title) {
            return;
        }
        else {
            this.activeMarkerTitle = marker.title;
        }
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    }

    onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: null
            })
        }

        this.activeMarkerTitle = "";
    };

    renderMarkers = (markers) => {

        //var image = 'https://s3.amazonaws.com/ntclimages/orbitLogo.png';
        //var image = 'https://s3.amazonaws.com/ntclimages/wifi.gif';
        var image = '';

        return (
            markers.map((marker, index) => {
                return <Marker
                    onClick={this.onMarkerClick}
                    onMouseover={this.onMouseOver}
                    title={"" + index}
                    info={marker.info} key={index}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    icon={image} />
            }
            ));
    }

    getCenter = (markers) => {
        if (true) {
            // return the panama center
            return { lat: 8.5380, lng: -80.1 };
        }
        let lat = 0;
        let lng = 0;
        let ignore = 0;
        for (let index in markers) {
            if (markers[index].lat === 0 || markers[index].lng === 0) {
                ignore += 1;
            }
            lat += markers[index].lat;
            lng += markers[index].lng;
        }

        // make sure we not dividing by 0
        let div = (markers.length - ignore) !== 0 ? (markers.length - ignore) : 1

        return { lat: lat / div, lng: lng / div };
    }

    convertGpsPosition = (pos) => {
        let lat = parseFloat(pos.lat.substring(0, 9), 10);
        let lng = parseFloat(pos.lng.substring(0, 10), 10);

        let latSign = (pos.lat[9] === "N") ? 1 : -1;
        let lngSign = (pos.lng[10] === "E") ? 1 : -1;

        return {
            info: pos.info,
            lat: (Math.floor(lat / 100) + ((lat % 100) / 60)) * latSign,
            lng: (Math.floor(lng / 100) + ((lng % 100) / 60)) * lngSign
        };
    }

    render() {
        const style1 = {
            height: this.props.height,
            position: 'relative'
        }
        const style2 = {
            width: '90%',
            height: '90%',
            position: 'relative',
        }

        if (!this.state.loaded) {
            return <div>Loading Maps</div>
        }

        let markers = [];
        for (let index in this.props.markers) {
            if (this.props.markers.length === 0) {
                break;
            }
            if (this.props.markers[index].lat !== "0000.0000N")
                markers.push(this.convertGpsPosition(this.props.markers[index]));
        }

        return (
            <div style={style1}>
                <center><Map google={window.google}
                    zoom={this.props.zoom} style={style2}
                    mapType={this.props.mapType}
                    onClick={this.onMapClicked}
                    initialCenter={this.getCenter(markers)}>

                    {this.renderMarkers(markers)}

                    <InfoWindow
                        marker={this.state.activeMarker}
                        visible={this.state.showingInfoWindow}>
                        <div>
                            {this.state.selectedPlace.info}
                        </div>
                    </InfoWindow>
                </Map></center>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: ('AIzaSyAugb6uKPRiLYNHII5GEgQxHVSTL73IUVk')
})(MapContainer)