import axios from "axios";
import { getTankReadingUri, getTankReadingStartEndUri } from "app/restHelper.jsx";
import { AppLogger } from "app/Logger";
import TanksTable from "app/db/TanksTable"
import { LogInHelper } from "app/logInHelper";
// import { Thumbnail } from "react-bootstrap";

let lastLevelMonitoring = [];
//let numberOfTanks = 0;
let lastReadingTime = 0;

export default class TanksReading {

    constructor() {
        this.customer = null;
        this.callBack = null;
        this.tank = null;
        this.numberOfTanks = 0;
        this.tanksInfoReady = this.tanksInfoReady.bind(this);
    }

    getLastReadingTime = () => {
        return lastReadingTime;
    }

    getSortReading(list, category) {
        let tt = new TanksTable();
        if (category === "name") {
            return list.sort((a, b) =>
                tt.getTankName(a.imei) > tt.getTankName(b.imei) ? 1 : -1);
        }
        else if (category === "capacity") {
            return list.sort((a, b) =>
                tt.getCapacity(a.imei) > tt.getCapacity(b.imei) ? 1 : -1);
        }
        else if (category === "liquid_type") {
            return list.sort((a, b) =>
                tt.getLiquidType(a.imei).toLowerCase() > tt.getLiquidType(b.imei).toLowerCase() ? 1 : -1);
        }
        else {
            return null;
        }
    }

    getLevelMonitoring(category) {
        return this.getSortReading(lastLevelMonitoring, category);
    }

    getLevelMonitoringForTank(imei) {
        let index = 0;
        for (index in lastLevelMonitoring) {
            if (lastLevelMonitoring[index].imei === imei) {
                return lastLevelMonitoring[index];
            }
        }
        return null;
    }

    updateTankReadings = (data) => {

        if (data !== null) {
            if (this.from === null) {
                let tt = new TanksTable();
                console.log(AppLogger("Readings from " + tt.getTankName(data.data.imei)));
                console.log(AppLogger("Sample " + data.data.sample_level + " Actual " + data.data.actual_level +
                    " IMEI " + data.data.imei + " server time " + data.data.server_time + " tank time " + data.data.tank_time));
                lastLevelMonitoring.push(data.data);
                if (lastReadingTime < data.data.server_time) {
                    lastReadingTime = data.data.server_time;
                }
            }
            else {
                let range = data.data;
                range['imei'] = new TanksTable().getImeiFromUrl(data.request.responseURL);
                //console.log("adding to array !!!!!!!!!!")
                this.fromToLevelMonitoring.push(range);
            }
        }
        this.numberOfreadings += 1;
        //console.log("!!!! sending req " + this.numberOfreadings, this.numberOfTanks)
        // call after all reading done
        if (this.numberOfTanks === this.numberOfreadings) {
            //console.log(AppLogger( "calling callback");
            if (this.from === null) {
                this.callBack && this.callBack();
            }
            else {
                let sorted = this.fromToLevelMonitoring.sort((a, b) => parseInt(a.imei, 16) - parseInt(b.imei, 16));
                this.callBack && this.callBack(sorted);
            }
        }
    }

    tanksInfoReady = () => {
        let tanks = new TanksTable().getCustomerTanks(this.customer);
        this.numberOfTanks = tanks.length;

        if (this.numberOfTanks === 0) {
            // customer with no tanks
            this.callBack && this.callBack();
        }

        this.numberOfreadings = 0;

        for (let index in tanks) {
            let uri = getTankReadingUri(tanks[index].imei, true);
            if (this.from != null && this.to != null) {
                this.numberOfTanks = this.tank ? 1 : this.numberOfTanks
                if (this.tank === null || this.tank.imei === tanks[index].imei) { // get the readings only for the selected tank
                    uri = getTankReadingStartEndUri(tanks[index].imei, this.from, this.to);
                }
                else {
                    continue;
                }
            }
            console.log(AppLogger("level monitoring uri = " + uri));

            axios
                .get(uri)
                .then(response => this.updateTankReadings(response))
                .catch(error => {
                    console.log(AppLogger("TanksReadings " + error));
                    this.updateTankReadings(null);
                });
            /*
                        axios({
                            method: 'get',
                            url: uri,
                            timeout: 60 * 4 * 1000,
                        }).then(response => this.updateTankReadings(response))
                            .catch(error => {
                                console.log(AppLogger("TanksReadings " + error));
                                this.updateTankReadings(null);
                            });*/
        }
    }

    setClassParams(callBack, from, to, tank) {
        this.callBack = callBack;
        this.from = from;
        this.to = to;
        this.customer = new LogInHelper().getLoggedInCustomerId();
        this.tank = tank
        let tt = new TanksTable();
        tt.start(this.tanksInfoReady, false);
    }

    getLastReading(callBack) {
        console.log(AppLogger("Asking for last readings"));
        lastLevelMonitoring = [];
        this.setClassParams(callBack, null, null, null);
    }

    getReadingFromTo(callBack, from, to, tank) {
        console.log(AppLogger("getReadingFromTo " + from + " " + to));
        this.fromToLevelMonitoring = [];
        this.setClassParams(callBack, from, to, tank);
    }
}