export const PRESSURE = 'pressure'
export const industries = ['Residential buildings', 'Commercial buildings', 'Hospitals', 'Hotels', 'Agriculture', 'Food and drinks', 'Oils and fuels', 'Emergency services', 'Utility service provider'];
export const liquids = ['water', 'diesel', 'fertilizer', PRESSURE];
export const sensors = [3, 4, 5, 6, 7, 8, 9, 10];

export const dictionary = {
    'en': {
        // Tank properties
        'tankName': 'Tank Name',
        'buildingName': 'Building Name',
        'customer': 'Customer',
        'volume': 'Volume',
        'leftInTank': 'Left In Tank',
        'lastUpdate': 'Last Update',
        'capacity': 'Capacity',
        'liquid': 'Liquid',
        'sensor type': 'Sensor type',
        'sensorLength': 'Sensor Length',
        'sensor': 'Sensor',
        'tankLocation': 'TANK LOCATION',
        'tanksLocations': 'TANKS LOCATION',
        'volumeHistory': 'VOLUME HISTORY',
        // Tank properties
        // ranges
        'Last 1H': 'Last 1H',
        'Last 6H': 'Last 6H',
        'Last 12H': 'Last 12H',
        'Last 24H': 'Last 24H',
        'Last 2 Days': 'Last 2 Days',
        'Last Week': 'Last Week',
        'Select Range': 'Select Range',
        'Custom Range': 'Custom Range',
        'latestData': 'LATEST DATA',
        // ranges


        'selectEn': 'en - English',
        'selectEs': 'es - Spanish',
        'logOut': 'Log Out',
        'customerName': 'Customer Name',
        'mailAddress': 'Mail Address',
        'addNewCustomer': 'Add New Customer',
        'add': 'Add',
        'cancel': 'Cancel',
        'update': 'Update',
        'delete': 'Delete',
        'addingNewUser': 'Adding New User',
        // industries
        'industry': 'Industry',
        'Residential buildings': 'Residential buildings',
        'Commercial buildings': 'Commercial buildings',
        'Hospitals': 'Hospitals',
        'Hotels': 'Hotels',
        'Agriculture': 'Agriculture',
        'Food and drinks': 'Food and drinks',
        'Oils and fuels': 'Oils and fuels',
        'Emergency services': 'Emergency services',
        'Utility service provider': 'Utility service provider',
        // liquid types
        'water': 'Water',
        'diesel': 'Diesel',
        'fertilizer': 'Fertilizer',
        'pressure': 'Pressure',

        // alerts and heartbeat
        "levelAlert": "Level Alert",
        "heartbeat": "Device Health",
        "Level OK": "Level OK",
        "Level Below": "Level Below",
        "Connected": "Connected",
        "Not Connected": "Not Connected",
    },
    'es': {
        // Tank properties
        'tankName': 'Tank Name',
        'buildingName': 'Building Name',
        'customer': 'Customer',
        'volume': 'Volume',
        'leftInTank': 'Left In Tank',
        'lastUpdate': 'Last update',
        'capacity': 'Capacity',
        'liquid': 'Liquid',
        'sensor type': 'Sensor type',
        'sensorLength': 'Sensor Length',
        'sensor': 'La Sensora',
        // Tank properties

        'tankLocation': 'Tank Location',
        'tanksLocations': 'Tanks Locations',
        'volumeHistory': 'Volume History',

        // ranges
        'Last 1H': 'Last 1H',
        'Last 6H': 'Last 6H',
        'Last 12H': 'Last 12H',
        'Last 24H': 'Last 24H',
        'Last 2 Days': 'Last 2 Days',
        'Last Week': 'Last Week',
        'Select Range': 'Select Range',
        'Custom Range': 'Custom Range',
        'latestData': 'Latest Data',
        // ranges

        'selectEn': 'en - Inglés',
        'selectEs': 'es - Español',
        'logOut': 'Cerrar sesión',
        'customerName': 'Nombre del cliente',
        'mailAddress': 'Mail Address',
        'addNewCustomer': 'Agregar nuevo cliente',
        'add': 'Añadir',
        'cancel': 'Cancelar',
        'update': 'Actualización',
        'delete': 'Borrar',
        'addingNewUser': 'Agregando nuevo usuario',
        // industries
        'industry': 'Industria',
        'Residential buildings': 'Edificios residenciales',
        'Commercial buildings': 'Edificios comerciales',
        'Hospitals': 'Hospitales',
        'Hotels': 'Hoteles',
        'Agriculture': 'Agricultura',
        'Food and drinks': 'Alimentos y bebidas',
        'Oils and fuels': 'Aceites y combustibles',
        'Emergency services': 'Servicios de emergencia',
        'Utility service provider': 'Proveedor de servicios públicos',
        // liquid
        'water': 'Agua',
        'diesel': 'Diesel',
        'fertilizer': 'Fertilizante',
        'pressure': 'Pressure',
    }
}

