import React from 'react';
import { Alert } from "react-bootstrap";

export function levelAlertView(alerts) {
    if (alerts.length === 0){
        return null;
    }

    return (
        <div>
            {alerts.map((alert, index) => {
                return (
                    <Alert bsStyle="danger" className="alert-with-icon" key={index}>
                        <center>
                            <span data-notify="icon" className="pe-7s-bell" />
                            <span data-notify="message">Level is below {alert.level}% on Tank: <q>{alert.name}</q></span>
                        </center>
                    </Alert>);
            })}
        </div>
    )
}

export function heartBeatView(hearBeatAlerts) {
    if (hearBeatAlerts.length === 0){
        return null;
    }

    return (
        <div>
            {hearBeatAlerts.map((heartBeatAlert,index) => {
                return (
                    <Alert bsStyle="danger" className="alert-with-icon" key={index}>
                        <center>
                            <span data-notify="icon" className="pe-7s-bell" />
                            <span data-notify="message">Tank: <q>{heartBeatAlert.name}</q> is not responding</span>
                        </center>
                    </Alert>);
            })}
        </div>)
}
