import React from "react";
import { ButtonGroup, Table, Grid, Row, Col, Button, FormGroup, FormControl } from "react-bootstrap";
import { CustomerForm } from "views/Admin/CustomerForm"
import { UsersForm } from "views/Admin/UsersForm"
import { AppLogger } from "../../app/Logger";
import CustomersTable from "../../app/db/CustomersTable";
import { Card } from "components/Card/Card.jsx";
import { LogInHelper } from "app/logInHelper";
import { translate } from "../../app/lang/lang";
import { TankForm } from "views/Admin/TankForm";
import TanksTable from "../../app/db/TanksTable";
import { getButtonStyle } from "../../app/adminHelper";

export default class Customers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            showCustomersModal: false,
            showTankForm: false,
            showUsersModal: false,
            selectedCustomer: null,
            filterStr: "",
            isManagement: props.location.pathname.search("Management") !== -1 ? true : false,
        };

        this.buttonStyle = getButtonStyle();

        let textPostfix = "Customer"

        if (this.state.isManagement) {
            textPostfix = "Building Management"
        }

        this.cardTitle = "Add " + textPostfix
        this.editText = "Edit " + textPostfix
        this.deleteText = "Delete " + textPostfix
        this.addNewText = "Add";
        this.filterTitle = "Filter"
        this.filterPlaceHolder = "Name"
    }

    componentDidMount() {
        this.readCustomers();
    }

    customerInfoReady = () => {
        console.log(AppLogger("customerInfoReady"));
        this.setState({ customers: new CustomersTable().getCustomers() });
    }

    readCustomers() {
        new CustomersTable().start(this.customerInfoReady, true);
    }

    readTanks = () => {
        console.log(AppLogger("readTanks"));
        new TanksTable().start(null, true);
    }

    operationCallBack = () => {
        this.readCustomers();
    }

    closeCustomersModal() {
        this.readCustomers();
        this.setState({ showCustomersModal: false, selectedCustomer: null });
    }

    closeUsersModal() {
        this.setState({ showUsersModal: false, selectedCustomer: null });
    }

    closeTankForm() {
        this.readTanks();
        this.setState({ showTankForm: false, selectedCustomer: null });
    }

    deleteCustomer(customer) {
        let confirmText = 'Deleting Customrt: Are you sure you want to delete ' + customer.name + " ?"
        if (window.confirm(confirmText)) {
            console.log(AppLogger("deleteCustomer " + customer.name));

            let customrtTable = new CustomersTable();
            customrtTable.deleteCustomer(customer.id, this.operationCallBack);
        }
    }

    addUser(customer) {
        this.setState({ showUsersModal: true, selectedCustomer: customer });
    }

    addTank(customer) {
        this.setState({ showTankForm: true, selectedCustomer: customer });
    }

    editCustomer = (customer) => {
        this.setState({ showCustomersModal: true, selectedCustomer: customer })
    }

    renderManageCustomersButton(customer, index) {
        return (
            <>
                <ButtonGroup aria-label="Basic example">
                    {this.renderPageButton(() => this.addUser(customer), "Add User")}
                    {!this.state.isManagement &&
                        this.renderPageButton(() => this.addTank(customer), "Add Tank")}
                    {this.renderPageButton(() => this.editCustomer(customer), this.editText)}
                    {this.renderPageButton(() => this.deleteCustomer(customer), this.deleteText)}
                </ButtonGroup>
            </>
        )
        // return (
        //     <DropdownButton
        //         bsStyle="primary"
        //         style={this.buttonStyle}
        //         title={"Settings"}
        //         key={index}
        //         id={`dropdown-basic-${index}`}
        //         drop={'left'}
        //     >
        //         <MenuItem eventKey="1" onClick={() => this.addUser(customer)}>Add User</MenuItem>
        //         {!this.state.isManagement &&
        //             < MenuItem eventKey="2" onClick={() => this.addTank(customer)}>Add Tank</MenuItem>
        //         }
        //         <MenuItem eventKey="3" onClick={() => this.editCustomer(customer)}>{this.editText}</MenuItem>
        //         <MenuItem divider />
        //         <MenuItem eventKey="4" onClick={() => this.deleteCustomer(customer)}>{this.deleteText}</MenuItem>
        //     </DropdownButton >
        // );
    }

    renderFilterCard() {
        let logInHelper = new LogInHelper();
        if (logInHelper.isAdmin() === false) {
            return null
        }

        return (
            <Col xs={6}>
                <Card
                    id={"filterCustomers"}
                    title={this.filterTitle}
                    hCenter={true}
                    content={
                        <form>
                            <FormGroup controlId="formBasicText">
                                <FormControl
                                    type="text"
                                    value={this.state.value}
                                    placeholder={this.filterPlaceHolder}
                                    onChange={(e) => this.setState({ filterStr: e.target.value })}
                                />
                            </FormGroup>
                        </form>
                    }
                />
            </Col>
        );
    }

    getFilteredCustomers = () => {
        let customers = [...this.state.customers];
        if (this.state.isManagement) {
            customers = customers.filter((customer) => customer.industry === "management");
        }
        else {
            customers = customers.filter((customer) => customer.industry !== "management");
        }

        if (this.state.customers.length === 0 || this.state.filterStr.length === 0) {
            return customers;
        }

        for (let index = customers.length - 1; index >= 0; index--) {
            let name = customers[index].name;
            if (name.search(this.state.filterStr) === -1) {
                customers.splice(index, 1);
            }
        }

        return customers;
    }

    renderCustomers() {
        //console.log(AppLogger( "rendring customers");

        let filteredCustomers = this.getFilteredCustomers();

        if (1)
            return this.renderCustomersTable(filteredCustomers);

        return (
            filteredCustomers.map((item, index) =>
                <Col xs={6} md={3} key={index}>
                    <Card
                        hCenter={true}
                        statsIcon="fa"
                        id="ManageCustomer"
                        title={
                            <div>
                                <div>{item.name}</div>
                            </div>}
                        category={
                            <object>
                                {translate('industry')}
                                <br />
                                {item.industry}
                            </object>
                        }
                        stats={null}
                        content={<center>{this.renderManageCustomersButton(item, index)}</center>}
                    />
                </Col>
            )
        );
    }

    renderCustomersTable(customrts) {
        let width = this.state.isManagement ? ['25%', '75', '0'] : ['25%', '50%', '25%']
        return (
            <div style={{ background: 'white', paddingLeft: 16, paddingRight: 16 }}>
                <Table striped bordered hover responsive >
                    <thead>
                        <tr>
                            <th style={{ width: width[0] }}>Name</th>
                            <th style={{ width: width[1] }}>Actions</th>
                            {!this.state.isManagement && <th style={{ width: width[2] }}>Industry</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {customrts.map((customer, index) => {
                            return this.renderTableRow(customer, index)
                        })
                        }
                    </tbody>
                </Table>
            </div>

        )
    }

    renderTableRow(customer, index) {
        return (
            <tr>
                <td>{customer.name}</td>
                <td>{this.renderManageCustomersButton(customer, index)}</td>
                {!this.state.isManagement && <td>{customer.industry}</td>}
            </tr>
        )
    }

    renderPageButton(onClick, text) {
        return (
            <Button style={this.buttonStyle} bsStyle="primary" onClick={onClick}>{text}</Button>
        )
    }

    renderAddCustomrtButton() {
        return (
            <center>
                <Button bsSize="large" bsStyle="primary" style={this.buttonStyle} className="pe-7s-add-user"
                    onClick={() => this.setState({ showCustomersModal: true, customer: null })}>
                    {" " + this.addNewText}
                </Button>
            </center>
        );
    }

    render() {
        let isAdmin = new LogInHelper().isAdmin();

        if (!isAdmin) {
            return null;
        }
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        {this.renderFilterCard()}
                        <Col xs={6}>
                            <Card
                                hCenter={true}
                                statsIcon="fa"
                                id="addCustomer"
                                title={this.cardTitle}
                                category=""
                                stats={null}
                                content={this.renderAddCustomrtButton()}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {this.renderCustomers()}
                    </Row>
                </Grid>
                <CustomerForm show={this.state.showCustomersModal}
                    customer={this.state.selectedCustomer}
                    onHide={() => this.closeCustomersModal()}
                    isManagement={this.state.isManagement} />
                <UsersForm show={this.state.showUsersModal}
                    customer={this.state.selectedCustomer}
                    onHide={() => this.closeUsersModal()} />
                <TankForm show={this.state.showTankForm}
                    onHide={() => this.closeTankForm()}
                    customer={this.state.selectedCustomer} />
            </div>
        );
    }
}