import React from 'react';
import { Grid, Col, Row } from "react-bootstrap"
import "./newTank.css"
//import $ from 'jQuery';
import "./newTank"
import Card from '../../components/Card/Card'
//import { index } from 'd3';
import TanksTable from "app/db/TanksTable"
import TankReadings from "app/db/TankReadings"
import NotificationHelper from "../../app/notificationHelper"
import { renderTanksParams } from "views/Tanks/Tanks"
import { getFormattedTime } from "app/adminHelper.jsx"
import ReactSpeedometer from "react-d3-speedometer"
import { isMobile } from '../../app/adminHelper';




class CompreddedTankRow extends React.Component {

    constructor(props) {
        super(props);

        this.width = isMobile() ? 12 : 3;
        this.getRangeForHours(1, this.props.reading);
        setInterval(() => {
            this.getRangeForHours(1, this.props.reading);
        }, 60000);
        this.state = { blink: false, rangeOpen: false, isLocked: false, lastHourTankReadings: [], rangeReady: false, differenceColor: 'rgb(52, 168, 83)', diference: '', changeIsZero: false, tank: null }
    }

    convertLittersToGallons = (volumeInL, floor = true) => {
        let gallons;
        if (floor) {
            gallons = Math.round(volumeInL * 0.264172)
        } else {
            gallons = (volumeInL * 0.264172);
        }
        return gallons;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    setBlinkingStatus(setBlink) {
        if (this.state.blink !== setBlink) {
            this.setState({ blink: setBlink })
        }
    }

    getRangeForHours = (timeInHours, tank) => {
        let tr = new TankReadings();
        let from = tr.getLastReadingTime() - (timeInHours * 60 * 60 * 1000);
        let to = tr.getLastReadingTime();
        tr.getReadingFromTo(this.rangeReady, from, to, tank);
    }

    rangeReady = (range) => {
        let tt = new TanksTable();

        range.map((tank) => {
            if (this.props.reading.imei === tank.imei) {
                var specificTank = tt.getCapacity(tank.imei);
                var difference = '';
                var differenceColor = 'black';
                var changeIsZero = false;
                if (tank.levels.length > 0) {
                    difference = (tank.levels[tank.levels.length - 1] - tank.levels[0]);

                    if (Math.floor(difference) > 0) {
                        difference = (this.convertLittersToGallons(specificTank / 100, false) * difference).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' gal (+' + difference;
                        differenceColor = 'rgb(52, 168, 83)';
                    } else if (Math.floor(difference) < 0) {
                        difference = (this.convertLittersToGallons(specificTank / 100, false) * difference).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' gal (' + difference;
                        differenceColor = 'rgb(217 48 37)';
                    } else {
                        difference = '(' + difference;
                        differenceColor = 'rgb(0 0 0)';
                        changeIsZero = true;
                    }

                    difference += '%) ';
                } else {
                    difference = '(0%) ';
                    differenceColor = 'rgb(0 0 0)';
                    changeIsZero = true;
                }
                this.setState({ tank: tt.getTank(tank.imei), lastHourTankReadings: tank, rangeReady: true, differenceColor: differenceColor, difference: difference, changeIsZero: changeIsZero });
            }
            return null
        })
    }

    render_close_text = () => {
        return (
            <div style={{ verticalAlign: 'center' }}>
                <div style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{this.state.tank.name}</div>
                <div>Capacity: {this.convertLittersToGallons(this.state.tank.capacity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} gallons</div>
                <div className={this.state.blink ? 'blink' : ''}>MAS DATOS</div>
            </div>)
    }

    render() {
        let hearBeatAlerts;
        let levelAlerts;
        let inReserves = "";
        let rotation = "";
        if (this.state.rangeReady) {
            let nh = new NotificationHelper();
            hearBeatAlerts = nh.getHeartBeatNotificationForTank(this.state.tank);
            levelAlerts = nh.getLevelNotificationsOnTank(this.state.tank);
            if (levelAlerts.length > 0) {
                this.setBlinkingStatus(true)
            } else {
                this.setBlinkingStatus(false)
            }

            inReserves = this.state.blink ? "blink" : this.props.reading.actual_level < this.state.tank.reserve ? "blinkOrange" : ""
            rotation = this.state.difference.split('(')[1].charAt(0) === '+' ? '' : 'rotate(180deg)';
        }

        let classForContent = (this.state.isLocked ? "tankDataContent" + (isMobile() ? "Mobile" : '') : '');

        return (
            <Grid fluid alig>
                <Row alignItems='center' style={{ textAlign: 'center' }}
                    onMouseLeave={() => this.setState({ rangeOpen: false })}>
                    <Card
                        hCenter={true}
                        statsIcon="fa"
                        id="compressedTank"
                        content={
                            <div>
                                <Col xs={this.width} height='100%' style={{ width: (isMobile() ? '89%' : '') }}>
                                    <div className="gradient-transparent-container">
                                        <div style={{ paddingTop: "6%", fontWeight: 'bold', color: '#184d69', marginBottom: '-8%' }}>
                                            {this.props.reading.actual_level}%
                                        </div>
                                        <div style={{ paddingTop: "6%", fontWeight: 'normal', fontSize: 20, color: '#184d69' }}>
                                            {this.state.rangeReady ? this.convertLittersToGallons(this.state.tank.capacity * this.props.reading.actual_level / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ""}gal
                                        </div>
                                    </div>
                                    <div id="sea" className={"sea " + inReserves}>
                                        <svg class='svg' style={{ height: this.props.reading.actual_level + 3 }} version="1.1" xmlns="http://www.w3.org/2000/svg">
                                            <defs>
                                                <pattern id="water" width=".25" height="2" patternContentUnits="objectBoundingBox">
                                                    <path fill="#3fbadf" d="M0.25,1H0c0,0,0-0.659,0-0.916c0.083-0.303,0.158,0.334,0.25,0C0.25,0.327,0.25,1,0.25,0.5z" />
                                                </pattern>
                                            </defs>
                                            <rect id="waves" class="water-fill" fill='url(#water)' width="3000" />
                                        </svg>
                                    </div>

                                </Col>
                                <Col xs={this.width + (isMobile() ? 0 : 1)} style={{ marginTop: (isMobile() ? '20vh' : '3.5vh'), marginBottom: (isMobile() ? '3vh' : ''), fontSize: 'calc(17px + 0.6vw)', fill: this.state.differenceColor, color: this.state.differenceColor }}>
                                    {(!this.state.isLocked && !this.state.rangeOpen) && this.state.rangeReady ?
                                        <center>
                                            {this.state.difference}
                                            {!this.state.changeIsZero && !this.state.isLocked && !this.state.rangeOpen ?
                                                <div style={{ display: 'inline-block' }}>
                                                    <svg version="1.1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" style={{
                                                        height: '2.5vh',
                                                        width: '3vh',
                                                        marginBottom: '-0.3vh',
                                                        transform: rotation
                                                    }} >
                                                        <path d="M6,0.002L0 6.002 4.8 6.002 4.8 11.9996 7.2 11.9996 7.2 6.002 12 6.002z"></path>
                                                    </svg>
                                                    1H</div>
                                                :
                                                ""
                                            }
                                        </center>
                                        :
                                        ""
                                    }

                                </Col>
                                <Col xs={this.width - (isMobile() ? 0 : 1)}>
                                    {this.state.rangeReady && !this.state.rangeOpen && !this.state.isLocked ?
                                        <div style={{ position: 'relative' }}>
                                            <ReactSpeedometer
                                                needleHeightRatio={0.7}
                                                needleTransitionDuration={1000}
                                                maxSegmentLabels={10}
                                                segments={5}
                                                minValue={0}
                                                maxValue={100}
                                                // fluidWidth={true}
                                                ringWidth={50}
                                                customSegmentStops={[0, 20, 40, 60, 80, 100]}
                                                // segmentColors={['firebrick', 'tomato', 'gold', 'limegreen']}
                                                endColor={'green'}
                                                startColor={'red'}
                                                value={this.props.reading.actual_level / this.state.tank.reserve * 100 > 100 ? 100 : (this.props.reading.actual_level / this.state.tank.reserve * 100).toFixed(2)}
                                                height={115}
                                                width={180}
                                                textColor={'black'}
                                            />
                                            <center>
                                                <div style={{
                                                    position: 'relative',
                                                    display: 'block',
                                                    marginTop: isMobile() ? '-20%' : '-67px',
                                                    color: 'white',
                                                    fontSize: '1.5vh'
                                                }}>
                                                    ({this.state.tank.reserve}% del tanque)
                                                </div>
                                                <div style={{
                                                    position: 'relative',
                                                    display: 'block',
                                                    marginTop: '-42px',
                                                    color: 'white',
                                                    fontSize: '1.6vh'
                                                }}>
                                                    {this.state.rangeReady ?
                                                        this.props.reading.actual_level > this.state.tank.reserve ?
                                                            this.convertLittersToGallons(this.state.tank.capacity * this.state.tank.reserve / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                            :
                                                            this.convertLittersToGallons(this.state.tank.capacity * this.props.reading.actual_level / 100).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                                        :
                                                        ""}gal
                                                </div>
                                            </center>
                                        </div>
                                        :
                                        ''}
                                </Col>
                                <Col id='tankDataCol'
                                    xs={this.width}
                                    onClick={() => this.setState({ isLocked: !this.state.isLocked, rangeOpen: !this.state.isLocked })}
                                    onMouseEnter={() => isMobile() ? null : this.setState({ rangeOpen: true })}
                                    onMouseLeave={() => isMobile() ? null : this.setState({ rangeOpen: false })}>
                                    <div id={isMobile() ? "tankDataContentMobile" : "tankDataContent"}
                                        className={classForContent}>
                                        {/* className={(this.state.isLocked ? "tankDataContent" : '') + (isMobile() ? "Mobile" : '')}> */}
                                        {(this.state.isLocked || this.state.rangeOpen) && this.state.rangeReady ?
                                            renderTanksParams(this.state.tank, this.props.reading, true, -15)
                                            :
                                            this.state.rangeReady ?
                                                this.render_close_text() : 'DATOS'}
                                    </div>
                                </Col>
                            </div>
                        }
                        stats={
                            <Row>
                                <div className="led-box" style={{ textAlign: 'left' }}>
                                    {/* {this.state.rangeReady ? console.log(hearBeatAlerts.length) : ''} */}
                                    {/* <div style={{ display: 'inline-block', marginTop: '9px', marginRight: '5px' }}>Device Status: </div> */}
                                    <div style={{ display: 'inline-block', marginRight: '5px', marginTop: '7px' }} className={this.state.rangeReady ? (hearBeatAlerts.length > 0 ? "led-red" : "led-green") : ''}></div>
                                    <div style={{ display: 'inline-block' }}>{this.state.rangeReady ? (hearBeatAlerts.length > 0 ? "Last connection: " + getFormattedTime(this.props.reading.server_time) : "Connected") : ''}</div>
                                </div>
                            </Row>
                        }
                    />
                    <div style={{
                        width: 700, padding: 6
                    }}>

                    </div>
                </Row >
            </Grid>
        )
    }
}

export default CompreddedTankRow