import React from "react";
import {
    Modal, Button, FormGroup, FormControl, ControlLabel,
    DropdownButton, MenuItem
} from "react-bootstrap";
import axios from "axios";
import { AppLogger } from "../../app/Logger";
import { liquids, sensors } from 'app/lang/dic.js'
import { translate } from "../../app/lang/lang";
import TanksTable from "../../app/db/TanksTable";
import { getButtonStyle } from '../../app/adminHelper'

class SettingsModal extends React.Component {

    render() {
        return (
            <Modal {...this.props} bsSize="lg" aria-labelledby="contained-modal-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm">
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.text.map((text, index) =>
                        <div key={index}>{text}</div>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}

export class TankForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imei: "",
            phone: '0000',
            name: "",
            building: "",
            reserve: -1,
            liquid_type: liquids[0],
            sensor_type: sensors[0],
            capacity: 0,
            high: 0,
            iccid: '12345', //default
            sampling_interval: 0,
            heartbeat_timeout: (60 * 60000),

            // settings modal
            showSettingsModal: false,
            settingsModalText: [],
            level_alerts: [],

            simConfigurationIndex: 0 // 0 for local sim 1 for monogoto
        };

        this.message = null;

        // form place holders
        this.imeiPh = "Tank ID (IMEI)";
        this.iccidPh = "Thing ICCID";
        this.phoneNumberPh = "Tank Phone number";
        this.namePh = "Tank Name";
        this.reservePh = "Reserve"
        this.buildingPh = "Building Name"
        this.capacityPh = "Capacity";
        this.highPh = "Hight";
        this.sampleIntervalPh = "Sample Interval";

        this.runTimer = true;

        // sim config
        this.simConfigurationTitle = [
            "Configuring Local SIM ",
            "Configuring Monogoto SIM "];

        this.ADD_NEW_TANK = true;
        this.UPDAE_TANK = false;
    }

    validateForm(validateType) {
        // validate type - true for "add tank" false for "update tank"
        if (this.state.imei.length !== 15 && this.state.imei.length !== 16) {
            return "Invalid IMEI - length must be 15 or 16 current length is " + this.state.imei.length;
        }

        if (this.state.simConfigurationIndex === 1 && this.state.iccid.length < 5) {
            return "Invalid iccid length, current length " + this.state.iccid.length;
        }

        if (this.state.sampling_interval < 60) {
            return "Invalid Sampling Interval - value is less the 60 seconds";
        }

        if (this.state.name === "") {
            return "Invalid tank name - name can't be empty";
        }

        if (this.state.reserve === -1) {
            return "Invalid tank reserve - reserve can't be -1";
        }

        if (this.state.capacity === 0) {
            return "Invalid tank capacity - capacity can't be 0";
        }

        if (this.state.high === 0) {
            return "Invalid tank high - high can't be 0";
        }

        // check if the tank name is already in the system
        // validate type - true for "add tank" false for "update tank"
        if (validateType === this.ADD_NEW_TANK) {
            if (new TanksTable().getTankFromName(this.state.name.trim()) !== null) {
                return "Invalid tank name - name already exist in the system";
            }
        }

        return null;
    }

    updateSettingsTimeOut = () => {
        this.setState({ showSettingsModal: false, settingsModalText: [] });
        this.props.onHide();
        clearInterval(this.timer);
    }

    updateSettingsModal = (show, text) => {
        console.log(AppLogger("updateSettingsModal " + show));
        console.log(AppLogger("updateSettingsModal " + this.state.settingsModalText));
        console.log(AppLogger("updateSettingsModal " + text));
        let textArray = this.state.settingsModalText.slice(0, this.state.settingsModalText.length);
        textArray.push(text);
        if (!show) { // close the modal after 2 seconds
            this.setState({ settingsModalText: textArray });
            this.timer = setInterval(this.updateSettingsTimeOut, 5000);
        }
        else {
            this.setState({ showSettingsModal: show, settingsModalText: textArray });
        }
    }

    addTankCallBack = (succedded) => {
        if (succedded) {
            this.updateSettingsModal(true, "done. please wait....");
            if (this.state.simConfigurationIndex === 0) {
                this.configureThingDone();
            }
            else {
                this.configureThing(this.setServerIp);
            }
        }
        else {
            this.updateSettingsModal(false, "Failed to add tank");
        }
    }

    addNewTank() {
        console.log(AppLogger("addNewTank"));

        // check if this is 
        let validateString = this.validateForm(this.ADD_NEW_TANK);

        if (validateString) {
            alert(validateString);
            return;
        }

        let tt = new TanksTable();
        tt.addTank(this.addTankCallBack, this.state, this.props.customer.id);

        this.updateSettingsModal(true, "Adding Tank to DB...");
    }

    updateTankCallBack = (succedded) => {
        if (succedded) {
            this.updateSettingsModal(true, "Updating Tank DB..., please wait...");
            if (this.samplingIntervalChanged) { // update if interval changed
                this.configureThing(this.setInterval);// update the thing
            }
            else {
                this.updateSettingsModal(false, "Setting thing done, please wait...");
            }
        }
        else {
            this.updateSettingsModal(false, "Fail to update tank");
        }
    }

    updateTank() {
        console.log(AppLogger("updateTank "));

        // check if this is 
        let validateString = this.validateForm(this.UPDAE_TANK);

        if (validateString) {
            alert(validateString);
            return;
        }

        let tt = new TanksTable();
        tt.updateTank(this.updateTankCallBack, this.state, this.props.customer.id);

        this.updateSettingsModal(true, "Updating Tank DB...");
    }

    liquidSelected = (value) => {
        let liquid = liquids[value]
        this.setState({ liquid_type: liquid });
    }

    sensorSelected = (value) => {
        let sensor = sensors[value]
        this.setState({ sensor_type: sensor });
    }

    renderIccdAndPhone = () => {
        if (this.state.simConfigurationIndex === 1) {
            return (
                <div>
                    <ControlLabel>Tank ICCID</ControlLabel>
                    {
                        !this.props.tank &&
                        <div>
                            <FormControl
                                type="text"
                                placeholder={this.iccidPh}
                                onChange={(e) => this.setState({ iccid: e.target.value })}
                            />
                        </div>
                    }
                    {this.props.tank && <p> {this.props.tank.iccid}</p>}
                    <ControlLabel>Phone number</ControlLabel>
                    <FormControl
                        type="text"
                        placeholder={this.phoneNumberPh}
                        onChange={(e) => this.setState({ phone: e.target.value })}
                    />
                </div>)
        }
        else {
            return null;
        }
    }

    renderTankFileds() {
        if (this.props.customer === null) {
            return null;
        }

        return (
            <FormGroup controlId="formBasicText">
                <ControlLabel>Tank ID (IMEI)</ControlLabel>
                {!this.props.tank &&
                    <div>
                        <FormControl
                            type="text"
                            placeholder={this.imeiPh}
                            onChange={(e) => this.setState({ imei: e.target.value })}
                        />
                    </div>
                }
                {this.props.tank && <p> {this.props.tank.imei}</p>}
                {this.renderIccdAndPhone()}
                <ControlLabel>Tank Name</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={this.namePh}
                    onChange={(e) => this.setState({ name: e.target.value })}
                />
                <ControlLabel>Building Name</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={this.buildingPh}
                    onChange={(e) => this.setState({ building: e.target.value })}
                />
                <ControlLabel>Tank Capacity (in liters)</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={this.capacityPh}
                    onChange={(e) => this.setState({ capacity: parseInt(e.target.value, 10) })}
                />
                <ControlLabel>Tank Hight (cm)</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={this.highPh}
                    onChange={(e) => this.setState({ high: parseFloat(e.target.value, 10) })}
                />
                <ControlLabel>Tank Reserve (%)</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={this.reservePh}
                    onChange={(e) => this.setState({ reserve: parseInt(e.target.value, 10) })}
                />
                <ControlLabel>Sample Interval (in second, min 60)</ControlLabel>
                <FormControl
                    type="text"
                    placeholder={this.sampleIntervalPh}
                    onChange={(e) => {
                        this.setState({ sampling_interval: parseInt(e.target.value, 10) });
                        this.samplingIntervalChanged = true;
                    }}
                />
                <div></div>
                <ControlLabel style={this.style}>{translate('sensor type')}</ControlLabel>
                <br />
                <DropdownButton
                    title={translate(this.state.liquid_type)}
                    id="liquidType"
                    style={getButtonStyle()}>
                    {liquids.map((liquid, index) =>
                        <MenuItem eventKey={index} key={index} onSelect={this.liquidSelected}>{translate(liquid)}</MenuItem>
                    )}
                </DropdownButton>
                <br />
                <ControlLabel style={this.style}>{translate('sensor')}</ControlLabel>
                <br />
                <DropdownButton
                    title={this.state.sensor_type + 'M'}
                    id="sensorType"
                    style={getButtonStyle()}>
                    {sensors.map((sensor, index) =>
                        <MenuItem eventKey={index} key={index} onSelect={this.sensorSelected}>{sensor}M</MenuItem>
                    )}
                </DropdownButton>
            </FormGroup>
        )
    }

    switchSimConfig = () => {
        this.setState({ simConfigurationIndex: this.state.simConfigurationIndex === 0 ? 1 : 0 })
    }

    renderFormTitle() {
        if (this.props.customer) {
            let title = "Adding new tank to - " + this.props.customer.name;
            if (this.props.tank) {
                return "Updating Tank - " + this.props.tank.name;
            }

            return (
                <div>
                    <div>
                        {title}
                    </div>
                    <div>
                        {this.simConfigurationTitle[this.state.simConfigurationIndex]}
                        <Button onClick={this.switchSimConfig} style={getButtonStyle()}>Switch</Button>
                    </div>
                </div>
            )
        }
        else {
            return "";
        }
    }

    getToken = (callback) => {
        let json = {
            "UserName": "guy@orbitmonitoring.com", "Password": "mono123!"
        };

        let uri = 'https://console.monogoto.io/Auth';

        axios.post(uri, json).then(response => {
            console.log(AppLogger("getToken succedded"));
            this.updateSettingsModal(true, "done, please wait...");
            this.token = response.data.token;
            callback && callback();
        }).catch(error => {
            console.log(AppLogger(error));
            this.updateSettingsModal(false, "Error on getting token");
        });
    }

    sendSemsToThing = (message, callback) => {
        var config = {
            headers: { 'Authorization': "Bearer " + this.token }
        };

        let json = {
            "Message": message, "From": this.state.phone
        };

        let uri = "https://console.monogoto.io/thing/ThingId_ICCID_" + this.state.iccid + "/sms";

        console.log(AppLogger("Sending thing API - " + json.Message + " " + json.From));

        axios.post(uri, json, config).then(response => {
            console.log(AppLogger("testApi succedded"));
            this.updateSettingsModal(true, "done, please wait...");
            callback && callback();
        }).catch(error => {
            console.log(AppLogger(error));
            this.updateSettingsModal(false, "error on configure Modem");
        }
        );
    }

    configureThing = (nextFunction) => {
        console.log(AppLogger("configureThing"));
        this.updateSettingsModal(true, "getting token...");
        // first get the token
        this.getToken(nextFunction);
    }

    setServerIp = () => {
        console.log(AppLogger("setServerIp"));
        this.updateSettingsModal(true, "Setting server IP...");
        // configure the server IP
        this.sendSemsToThing("pw123456,server=18.214.154.46,5005", this.setServerIpDone);
    }

    setServerIpDone = () => {
        console.log(AppLogger("setServerIpDone"));
        this.nextFunction = this.setProtocol;
        this.timer = setInterval(this.smsTimeOut, 15000);
    }

    setProtocol = () => {
        this.updateSettingsModal(true, "Setting protocol...");
        this.sendSemsToThing("pw123456,protocol=udp", this.setProtocolDone);
    }

    setProtocolDone = () => {
        console.log(AppLogger("setProtocolDone"));
        this.nextFunction = this.setInterval;
        this.timer = setInterval(this.smsTimeOut, 15000);
    }

    setInterval = () => {
        console.log(AppLogger("setInterval"));
        this.updateSettingsModal(true, "Setting interval...");
        this.sendSemsToThing("pw123456,interval=" + this.state.sampling_interval + "s," + this.state.sampling_interval + "s,0m,0d", this.configureThingDone);
    }

    configureThingDone = () => {
        console.log(AppLogger("configureThingDone"));
        this.updateSettingsModal(false, "Setting thing done.");
    }

    smsTimeOut = () => {
        clearInterval(this.timer);
        this.nextFunction && this.nextFunction();
    }


    updateData = () => {
        if (this.props.tank) {
            let liquidTypeIndex = 0;
            let sensorTypeIndex = 0;
            if (this.props.tank) {
                this.imeiPh = this.props.tank.imei;
                this.iccidPh = this.props.tank.iccid;
                this.phoneNumberPh = this.props.tank.phone;
                this.namePh = this.props.tank.name;
                this.buildingPh = this.props.tank.building;
                this.capacityPh = this.props.tank.capacity;
                this.reservePh = this.props.tank.reserve;
                this.highPh = this.props.tank.high;
                this.sampleIntervalPh = this.props.tank.sampling_interval;
                for (let index in liquids) {
                    if (liquids[index] === this.props.tank.liquid_type) {
                        liquidTypeIndex = parseInt(index, 10);
                        break;
                    }
                }

                for (let index in sensors) {
                    if (sensors[index] === this.props.tank.sensor_type) {
                        sensorTypeIndex = parseInt(index, 10);
                        break;
                    }
                }

                this.setState({
                    imei: this.imeiPh,
                    phone: this.phoneNumberPh,
                    name: this.namePh,
                    building: this.buildingPh,
                    capacity: this.capacityPh,
                    reserve: this.reservePh,
                    high: this.highPh,
                    iccid: this.iccidPh,
                    sampling_interval: this.sampleIntervalPh,
                    liquid_type: liquids[liquidTypeIndex],
                    sensor_type: sensors[sensorTypeIndex]
                })
            }
        }
        clearInterval(this.timer);
    }

    render() {
        console.log(AppLogger("render"));

        if (this.props.show === true) {
            if (this.runTimer) {
                this.timer = setInterval(this.updateData, 1000);
                this.runTimer = false;
                this.samplingIntervalChanged = false;
            }
        }
        else {
            this.runTimer = true;
        }

        return (
            <Modal {...this.props} bsSize="lg" aria-labelledby="contained-modal-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm">
                        {this.renderFormTitle()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        {this.renderTankFileds()}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="warning" onClick={this.props.onHide}>Cancel</Button>
                    {this.props.tank &&
                        <Button bsStyle="success" onClick={() => this.updateTank()}>Update</Button>}
                    {!this.props.tank &&
                        <Button bsStyle="success" onClick={() => this.addNewTank()}>Add</Button>}
                </Modal.Footer>
                <SettingsModal
                    show={this.state.showSettingsModal}
                    title={"Setting Tank"}
                    text={this.state.settingsModalText} />
            </Modal>
        );
    }
}

export default TankForm;
