import React, { Component } from "react";
import { translate } from "../../app/lang/lang";
import { getFilteredReadings } from "../../app/readingsHelper"
import MapContainer from "../../components/App/Map"
import { Card } from "../../components/Card/Card"
import { isAppReady, getBackgroundColor } from "../../app/adminHelper";
import { Grid, Col, Table } from "react-bootstrap";
import TanksReading from "../../app/db/TankReadings";
import TanksTable from "../../app/db/TanksTable";
import Spinner from "../App/Spinner"

class Locations extends Component {

  constructor(props) {
    super(props);
    this.state = { lastTanksReadings: [] };
  }

  componentDidMount() {
    //update reading timer
    this.timer = setInterval(this.tick, 250);
  }

  componentWillUnmount = () => {
    clearInterval(this.timer);
  }

  tick = () => {
    if (isAppReady()) {
      this.setState({ lastTanksReadings: new TanksReading().getLevelMonitoring("name") });
      clearInterval(this.timer);
    }
  }

  renderInfo = (reading) => {
    let tt = new TanksTable();
    let tank = tt.getTank(reading.imei);

    return (
      <Table striped bordered condensed hover>
        <tbody>
          <tr>
            <td><center>Tank Name</center></td>
            <td><center>{tank.name}</center></td>
          </tr>
          <tr>
            <td><center>Building</center></td>
            <td><center>{tank.building}</center></td>
          </tr>
        </tbody>
      </Table>
    )
  }

  getMarkers = () => {
    let filterStr = this.props.filterStr;
    if (filterStr === null || filterStr === undefined) {
      filterStr = "";
    }
    let markers = [];
    let readings = getFilteredReadings(this.state.lastTanksReadings, filterStr);
    for (let index in readings) {
      let info = this.renderInfo(readings[index]);
      markers.push({
        info: info,
        lat: readings[index].gps.lat,
        lng: readings[index].gps.long
      })
    }

    return markers;
  }

  render() {

    let markers = this.getMarkers();
    let contentHeight = this.props.fromDashboard === true ? '' : '120%';

    if (markers.length === 0) {
      return (
        <div className="content" style={{ backgroundColor: getBackgroundColor(), height: contentHeight }}>

          <Col md={12}>
            <Card
              hCenter={true}
              title="Loading Location..."
              content={<center><Spinner /></center>}
            /></Col>
        </div>
      )
    }

    let title = translate("tankLocation");
    if (markers.length > 1) {
      title = translate("tanksLocations")
    }

    let height = this.props.fromDashboard === true ? '40%' : '70%';
    contentHeight = this.props.fromDashboard === true ? '' : '110%';

    return (
      <div className="content" style={{ backgroundColor: getBackgroundColor(), height: contentHeight }}>
        <Grid fluid alig>
          <Col xs={12} style={{
            paddingRight: '0px',
            paddingLeft: '0px'
          }}>
            <Card
              hCenter={true}
              statsIcon="fa fa-history"
              id="chartHours"
              title={title}
              stats="Updated"
              content={<MapContainer height={height} markers={markers} zoom={7} mapType={'HYBRID-removeme'} />}
            />
          </Col>
        </Grid>
      </div>
    );
  }
}

export default Locations;
