import React, { Component } from "react";
import { Navbar } from "react-bootstrap";

import HeaderLinks from "./HeaderLinks.jsx";
import { getPrimaryColor, getPrimarySideColor } from "../../app/adminHelper"
import dashboardRoutes from "routes/dashboard.jsx";
import logo from "assets/img/orbitBanner3000.png";
import user from "assets/img/faces/userIcon.png";
import LogInHelper from "../../app/logInHelper.jsx";
import { getCurrentFormattedTime } from "../../app/adminHelper.jsx"
//import { translate } from "../../app/lang/lang.js";

class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
      count: 1
    };
    this.timer = setInterval(this.tick, 10000);

  }

  tick = () => {
    // refreshing the header to update the time
    this.setState({ count: this.state.count + 1 });
  }

  mobileSidebarToggle(e) {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    //    var node = document.createElement("div");
    //    node.id = "bodyClick";
    //    node.onclick = function() {
    //      this.parentElement.removeChild(this);
    //      document.documentElement.classList.toggle("nav-open");
    //    };
    //    document.body.appendChild(node);
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }

  userClicked = () => {
    if (true) {
      return;
    }
    if (window.confirm("Log Out ?") === true) {
      let logIn = new LogInHelper();
      logIn.logOut();
      window.location.reload();
    }
  }

  render() {
    let width = window.innerWidth;
    let headerHeigth = '11.2%';
    let showLogo = false;
    let imageHeigth = '60%';
    let imageWidth = '90%';
    if (width < 991) {
      showLogo = true;
    }

    let userName = new LogInHelper().getLogedInUser();

    if (true) {
      return (
        <Navbar fluid style={{ backgroundColor: getPrimaryColor() }} >
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
          {showLogo === true &&
            <center style={{ height: headerHeigth, display: 'flex' }}>
              <a href="#pablo" style={{ margin: 'auto' }}>
                <img src={logo} alt="logo_image" style={{ height: imageHeigth, width: imageWidth }} />
              </a>
            </center>
          }
          {showLogo === false &&
            <div style={{ width: '100%', color: getPrimarySideColor(), height: '11.2%', display: 'flex' }}>
              <div style={{ width: '100%', float: 'rigth', textAlign: 'right', margin: 'auto', fontSize: '20' }}>
                <b>{getCurrentFormattedTime()}</b>
              </div>
              <div style={{ float: 'rigth', width: '20%', margin: 'auto', textAlign: 'center' }}>
                <figure>
                  <img style={{ maxWidth: '40%', height: '50%' }} src={user} alt="user_image" onClick={this.userClicked} />
                  <figcaption ><b>{userName}</b></figcaption>
                </figure>
              </div>
            </div>
          }
        </Navbar>
      );
    }

    return (
      <Navbar fluid style={{ backgroundColor: getPrimaryColor() }} >
        <Navbar.Header style={{ textAlign: 'center', width: '100%', float: 'none', display: 'inlineBlock' }}>
          <Navbar.Brand>
            <div >&nbsp;&nbsp;&nbsp;&nbsp;</div>
          </Navbar.Brand>
          <Navbar.Brand>
            <a href="#pablo" style={{ color: getPrimarySideColor(), height: headerHeigth }}><b>{this.getBrand()}</b></a>
          </Navbar.Brand>
          <Navbar.Brand style={{ position: 'absolute', width: '100%', left: '0', textAlign: 'center', margin: '0 auto' }}>
            {showLogo === true && <img src={logo} alt="logo_image" style={{ height: '10%' }} />}
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Navbar.Collapse>
          <HeaderLinks />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
