const positionMonitoring = "/positionMonitoring";
//const tanksSettings = "/tanksSettings";
const customers = "/customers";
const users = "/users";
const levelMonitoring = "/tanklevels";

function getServer() {
    let debug = false;

    return debug === false ?
        'https://euhpo9j3yi.execute-api.us-west-2.amazonaws.com/api/tanks' :
        'http://localhost:3000';
}

function getNewServer() {
    return 'https://qbrc08rmmd.execute-api.us-east-1.amazonaws.com/prod'
    // return 'https://jx7ltqwdt6.execute-api.us-east-1.amazonaws.com/prod';
    //return 'https://wv6wvd40zb.execute-api.us-east-1.amazonaws.com/prod';
    //return 'https://x98na0g8vl.execute-api.us-east-1.amazonaws.com/dev'
}

export function getTankReadingUri(tankId, isLast) {
    if (tankId) {
        if (isLast) {
            return getNewServer() + levelMonitoring + "/" + tankId + "?last=true";
        }
        else {
            return getNewServer() + levelMonitoring + "/" + tankId;
        }
    }
    else
        return getNewServer() + levelMonitoring;
}

export function getTankReadingStartEndUri(tankId, from, to) {
    return getNewServer() + levelMonitoring + "/" + tankId + "?from=" + from + "&to=" + to;
}

export function getCustomersUri() {
    return getNewServer() + customers;
}

export function getUsersUri() {
    return getNewServer() + users;
}

export function getCustomerUri(customer) {
    return getNewServer() + "/customers/" + customer;
}

export function getPositionMonitoringUri(tankId) {
    return getServer() + positionMonitoring + "/" + tankId;
}

export function getTankSettingsUri() {
    return getNewServer() + "/tanks";
}