import moment from 'moment'
import variables from '../assets/sass/lbd/_variables.scss'
let appIsready = false;
let swVersion = "not set";

export function getVersion() {
    return swVersion;
}
export function setVersion(ver) {
    swVersion = ver;
}

export function appIsReady(ready) {
    appIsready = ready;
}

export function isAppReady() {
    return appIsready;
}

export function getPrimaryColor() {
    return variables.primaryColor;
}

export function getPrimaryTextColor() {
    return variables.primaryTextColor;
}

export function getMenuTextColor() {
    return variables.menuTextColor;
}

export function getBackgroundColor() {
    return variables.backgroundColor;
}

export function getPrimarySideColor() {
    return variables.primarySideColor;
}

export function getPrimaryColorStyle() {
    return { color: getPrimaryColor() };
}

export function getButtonStyle() {
    return { margin: '3px', backgroundColor: getPrimarySideColor(), color: 'white', opacity: '100%' };
}

export function getFormattedTime(time) {
    return moment.utc(time - 5 * 60 * 60 * 1000).format('MM/DD-HH:mm');
}
export function getDetailedFormattedTime(time) {
    return moment.utc(time - 5 * 60 * 60 * 1000).format('hh:mm a Do of MMMM YYYY');
}

export function getCurrentFormattedTime() {
    let date = new Date();
    let mom = moment.utc(date).add(-date.getTimezoneOffset() / 60, 'hours');
    return mom.format('hh:mm a Do of MMMM YYYY');
}

export function isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    }
    else {
        return false;
    }
}

export function isAndroid() {
    if (/Android/i.test(navigator.userAgent)) {
        return true;
    }
    else {
        return false;
    }
}

export function isIos() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        return true;
    }
    else {
        return false;
    }
}