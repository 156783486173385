import { Cookies } from 'react-cookie';
import { dictionary } from 'app/lang/dic.js';

const langKey = 'lang';
let supportLocalStorage = undefined;
let lang = null;

export const isLocalStorageSupported = () => {

    if (supportLocalStorage === undefined) {
        try {
            // test local storage
            window.localStorage.setItem("just", "just");
            supportLocalStorage = true;
        }
        catch (e) {
            supportLocalStorage = false;
        }
    }
    
    return supportLocalStorage;
}

export const getLang = () => {
    if (lang !== null){
        return lang;
    }

    if (isLocalStorageSupported()){
        window.localStorage.getItem(langKey);
    }
    else{
        let cookie = new Cookies();
        lang = cookie.get(langKey);
    }

    if (lang && (lang !== 'en' || lang !== 'es')) {
        return lang;
    }

    return setLang('en');
}

export const setLang = _lang => {

    if (isLocalStorageSupported()){
        window.localStorage.setItem(langKey, _lang);
    }
    else{
        let cookie = new Cookies();
        cookie.set(langKey, _lang);
    }

    lang = _lang;

    return lang;
}

export const translate = text => {
    let translated = dictionary[getLang()][text];
    if (translated) {
        return translated
    }
    else {
        return text + ' not fond in dic';
    }
}
