import React from "react";
import { Modal, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { AppLogger } from "../../app/Logger";
import UsersTable from "../../app/db/UsersTable";
import { getPrimaryTextColor } from 'app/adminHelper'
import { translate } from "../../app/lang/lang";

export class UsersForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { userName: "",password:"", hideButtons: false, settingMessage: null };

        this.style = { 'color': getPrimaryTextColor() };
    }

    restCallBack = (message) => {
        if (message) {
            this.setState({ hideButtons: false, settingMessage: message });
        }
        else {
            this.setState({ hideButtons: false, settingMessage: "done" });
            this.props.onHide();
        }
    }

    addNewUser() {
        this.setState({ hideButtons: true, settingMessage: translate('addingNewUser') });
        let usersTable = new UsersTable();
        usersTable.add(
            this.state.userName,
            this.state.password,
            this.props.customer.id,
            this.props.customer.name,
            this.restCallBack);
    }

    validate = (str) => {
        if ((str.length > 2) && (str.indexOf(' ')) === -1) {
            return 'success'
        }
        else{
            return 'error';
        }
    }

    validatUser() {
        return this.validate(this.state.userName);
    }

    validatPass = () => {
        return this.validate(this.state.password);
    }

    renderFormUsersFiled() {

        return (
            <div>
                <FormGroup controlId="formBasicText"
                    validationState={this.validatUser()}>
                    <center><ControlLabel style={this.style}>User Name</ControlLabel></center>
                    <FormControl
                        type="text"
                        placeholder="User Name"
                        onChange={(e) => { this.setState({ userName: e.target.value, settingMessage: null }) }}
                    />
                </FormGroup>
                <FormGroup controlId="formBasicText"
                    validationState={this.validatPass()}>
                    <center><ControlLabel style={this.style}>Password</ControlLabel></center>
                    <FormControl
                        type="text"
                        placeholder="Password"
                        onChange={(e) => { this.setState({ password: e.target.value, settingMessage: null }) }}
                    />
                </FormGroup>
            </div>
        )
    }

    createForm() {
        return (
            <form>
                {this.renderFormUsersFiled()}
                <center><div>{this.state.settingMessage}</div></center>
            </form>
        );
    }

    renderButtons() {
        if (this.state.hideButtons === true) {
            return null;
        }
        return (
            <div>
                <Button onClick={this.props.onHide}>Cancel</Button>
                {this.validatUser() === 'success' && this.validatPass() === 'success' &&
                    <Button onClick={() => this.addNewUser()}>Add</Button>
                }
            </div>
        );
    } 

    render() {
        console.log(AppLogger( "render"));
        return (
            <Modal {...this.props} bsSize="small" aria-labelledby="contained-modal-title-sm">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-sm" style={this.style}>
                        Add New User
                    </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {this.createForm()}
                </Modal.Footer>
                <Modal.Footer>
                    {this.renderButtons()}
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UsersForm;