import React, { Component } from "react";
import { getPrimaryTextColor, getPrimarySideColor, getBackgroundColor, isMobile } from "../../app/adminHelper";
import { Card } from "../../components/Card/Card"
import { Col, Row } from "react-bootstrap";
import ReactSpeedometer from "react-d3-speedometer"
import systemStatusImg from "assets/img/systemStatus.png";
import arrow from "assets/img/arrow.png";
import posed from 'react-pose';
 
class Indicators extends Component {

    state = { isVisible: true, delay: 500 };

    componentDidMount() {
        setInterval(() => {
            this.setState({ isVisible: !this.state.isVisible });
        }, this.state.delay);
    }


    renderSpeedo = (title, value, maxValue, segments, bottomString) => {

        return (
            <div style={{ width: this.outerWidth, height: "220px ", borderStyle: 'solid', borderRadius: '5px', borderColor: getPrimarySideColor() }}>
                <div style={{ width: this.innerWidth }}>
                    <div>
                        <h5><b><font color={getPrimarySideColor()}>{title}</font></b></h5>
                    </div>
                    <ReactSpeedometer
                        maxValue={maxValue}
                        value={value}
                        width={this.innerWidth}
                        needleColor={getPrimarySideColor()}
                        textColor={getPrimarySideColor()}
                        segments={segments}
                        currentValueText={bottomString}
                        needleHeightRatio={0.6}
                        needleTransitionDuration={2000}
                        needleTransition="easeBackOut"
                        ringWidth={15}
                    />
                </div>
            </div>);
    }

    renderTotalLiquids = () => {
        let bottomString = "24.5 KL";
        return this.renderSpeedo("Total Water In Building", 24.5, 30, 8, bottomString);
    }

    renderExtinguishing = () => {
        let bottomString = "76 %";
        return this.renderSpeedo("Extinguishing Reserved", 76, 100, 3, bottomString);
    }

    renderStatusText = (status, type) => {

        const Text = posed.div({
            hidden: {
                scale: 1,
                opacity: 0.0,
            },
            visible: {
                scale: 1.2,
                opacity: 1,
                transition: { type: "spring" }
            }

        });

        const { isVisible } = this.state;

        let textStyle = { position: 'absolute', top: '30%' }
        let arrowStyle = { position: 'absolute', top: '80%' }
        let left = '';
        let arrowLeft = '';
        let t1 = '';
        let t2 = '';
        switch (status) {
            case 'ok':
                left = '75%';
                arrowLeft = '80%';
                t1 = 'Good';
                t2 = 'System in good condition';
                break;
            case 'warning':
                left = '40%';
                arrowLeft = '47%';
                t1 = 'Warning';
                t2 = 'Building tanks has alerts'
                break;

            case 'error':
                left = '15%';
                arrowLeft = '18%';
                t1 = 'Error';
                t2 = 'Building tanks has criticalalerts'
                break;


            default:
                return null;
        }

        switch (type) {
            case 0:
                return (
                    {
                        t1:
                            <div>
                                <Text style={{ ...textStyle, left: left }} pose={isVisible ? 'visible' : 'hidden'}>{t1}</Text>
                                <img src={arrow} alt="logo_image" style={{...arrowStyle,left:arrowLeft,width:'10%', visibility:isVisible ? 'visible' : 'hidden'}}/>
                                </div>
                        ,
                        t2: t2
                    }
                )
            case 1:
            return (
                {
                    t1:
                        <div>
                            <Text style={{ ...textStyle, left: left }} pose={isVisible ? 'visible' : 'hidden'}>{t1}</Text>
                            <img src={arrow} alt="logo_image" style={{...arrowStyle,left:arrowLeft,width:'10%'}}/>
                            </div>
                    ,
                    t2: t2
                }
            )
            case 2:
            return (
                {
                    t1:
                        <div>
                            <Text style={{ ...textStyle, left: left }} pose={isVisible ? 'visible' : 'visible'}>{t1}</Text>
                            <img src={arrow} alt="logo_image" style={{...arrowStyle,left:arrowLeft,width:'10%', visibility:isVisible ? 'visible' : 'hidden'}}/>
                            </div>
                    ,
                    t2: t2
                }
            )

            default:
                return null;
        }

    }

    renderStatus = type => {
        let status = 'warning';// calculate the status for the building

        let texts = this.renderStatusText(status, type);

        return (
            <div>
                <br />
                <div style={{ width: this.outerWidth, borderStyle: 'solid', borderRadius: '5px', borderColor: getPrimarySideColor() }}>
                    <h5><b><font color={getPrimarySideColor()}>System Condition</font></b></h5>
                    <div style={{ position: 'relative', fontSize: '15px', fontStyle: 'italic', fontWeight: 'bold' }}>
                        <img src={systemStatusImg} alt="logo_image" style={{ width: '100%', padding: '10px' }} />
                        {texts.t1}
                    </div>
                    <h5><b><font color={getPrimarySideColor()}>{texts.t2}</font></b></h5>
                </div>
            </div>

        )
    }

    render() {
        let height = this.props.fromDashboard === true ? '' : '120%';
        let title = "SYSTEM HELTH";

        this.outerWidth = window.innerWidth < 400 ? 250 : 300;
        this.innerWidth = this.outerWidth - 40;

        return (
            <div className="content" style={{ backgroundColor: getBackgroundColor(), height: height }}>
                {isMobile() &&
                    <Col xs={12} >
                        <Card
                            hCenter={true}
                            id="chartHours"
                            title={title}
                            color={getPrimaryTextColor()}
                            content={
                                <div>
                                    <center>
                                        <Row>
                                            <Col xs={12} >
                                                {this.renderTotalLiquids()}
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col xs={12}>
                                                {this.renderExtinguishing()}
                                            </Col >
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div>
                                                    {this.renderStatus(0)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div>
                                                    {this.renderStatus(1)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div>
                                                    {this.renderStatus(2)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </center>
                                </div>
                            }
                        />
                    </Col>
                }
                {isMobile() === false &&
                    <Col xs={12} >
                        <Card
                            hCenter={true}
                            id="chartHours"
                            title={title}
                            color={getPrimaryTextColor()}
                            content={
                                <div>
                                    <center>
                                        <Row>
                                            <Col xs={6} >
                                                {this.renderTotalLiquids()}
                                            </Col>
                                            <Col xs={6}>
                                                {this.renderExtinguishing()}
                                            </Col >
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div>
                                                    {this.renderStatus(0)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div>
                                                    {this.renderStatus(1)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <div>
                                                    {this.renderStatus(2)}
                                                </div>
                                            </Col>
                                        </Row>
                                    </center>
                                </div>
                            }
                        />
                    </Col>
                }
            </div>
        )
    }
}

export default Indicators;
