let logList=[];

export const AppLogger = (msg) => {
    var d = new Date();
    d = ('0' + d.getHours()).slice(-2) + ":" + ('0' + d.getMinutes()).slice(-2) + ":" + ('0' + d.getSeconds()).slice(-2);
    var log = d + ": " + msg;
    logList.unshift(log);
    if (logList.length>500){
        logList.splice(logList.length-1-20,20);
    }
    return (log);
 }

 export const AppLogerMessages = () => {
    return logList;
 }
