import axios from "axios";
import { getTankSettingsUri } from "app/restHelper.jsx";
import { AppLogger } from "app/Logger";

let tankList = [];

export default class TanksTable {

    getTanks() {
        return tankList;
    }

    getCustomerTanks(customersId) {
        let debug = false;
        let tanks = [];
        for (let index in tankList) {
            if (tankList[index].customer === customersId ||
                customersId === "admin") {
                tanks.push(tankList[index]);
                if (debug === true) {
                    break;
                }
            }
        }

        return tanks;
    }

    getTank(imei) {
        for (let index in tankList) {
            if (tankList[index].imei === imei) {
                return tankList[index];
            }
        }

        return null;
    }

    getTankFromName(tankName) {
        for (let index in tankList) {
            if (tankList[index].name === tankName) {
                return tankList[index];
            }
        }

        return null;
    }

    getImeiFromUrl = (str) => {
        for (let index in tankList) {
            if (str.search(tankList[index].imei) !== -1) {
                return tankList[index].imei;
            }
        }

        return null;
    }

    getTankName(imei) {
        return this.getTank(imei).name;
    }

    getCustomerId(imei) {
        return this.getTank(imei).customer;
    }

    getCapacity(imei) {
        return this.getTank(imei).capacity;
    }

    getLiquidType(imei) {
        return this.getTank(imei).liquid_type;
    }


    hasTanks(customerId) {
        for (let index in tankList) {
            if (tankList[index].customer === customerId) {
                return true;
            }
        }

        return false;
    }

    addTank = (callBack, formState, customerId) => {
        let postJson = {
            'imei': formState.imei,
            'customer': customerId,
            'iccid': formState.iccid,
            'liquid_type': formState.liquid_type,
            'capacity': formState.capacity,
            'high': formState.high,
            'sensor_type': formState.sensor_type,
            'heartbeat_timeout': formState.heartbeat_timeout,
            'sampling_interval': formState.sampling_interval,
            'phone': formState.phone,
            // split
            'name': formState.name.trim() + '--building--' + formState.building.trim() + '--building--' + formState.reserve,
            'level_alerts': formState.level_alerts
        };

        axios.post(getTankSettingsUri(), postJson).then(response => {
            console.log(AppLogger("adding new tank succedded"));
            callBack && callBack(true);
        }).catch(error => {
            console.log(AppLogger(error));
            callBack && callBack(false);
        });
    }

    updateTank = (callBack, tank, customerId) => {
        let postJson = {
            'imei': tank.imei,
            'customer': customerId,
            'iccid': tank.iccid,
            'liquid_type': tank.liquid_type,
            'capacity': tank.capacity,
            'high': tank.high,
            'sensor_type': tank.sensor_type,
            'heartbeat_timeout': tank.heartbeat_timeout,
            'sampling_interval': tank.sampling_interval,
            'phone': tank.phone.trim(),
            'name': tank.name.trim() + '--building--' + tank.building.trim() + '--building--' + tank.reserve,
            'level_alerts': tank.level_alerts
        };

        axios.put(getTankSettingsUri() + "/" + tank.imei, postJson).then(response => {
            console.log(AppLogger("updating tank succedded"));
            callBack && callBack(true);
        }).catch(error => {
            console.log(AppLogger(error));
            callBack && callBack(false);
        }
        );
    }

    deleteTank = (imei, callBack) => {
        let url = getTankSettingsUri() + "/" + imei;
        console.log(AppLogger("deleteTank " + url));

        axios
            .delete(url)
            .then(response => {
                console.log(AppLogger("deleting tank succedded"));
                callBack && callBack();
            })
            .catch(error => console.log(AppLogger(error)));
    }

    readTanks(callBack) {
        let uri = getTankSettingsUri();
        console.log(AppLogger("readTanks " + uri));
        axios.get(uri)
            .then(response => {
                tankList = response.data;
                //update building
                for (let index in tankList) {
                    // split
                    let names = tankList[index].name.split("--building--");
                    if (names.length === 1) {
                        tankList[index].name = names[0];
                        tankList[index].building = '';
                        tankList[index].reserve = '';
                    } else if (names.length === 2) {
                        tankList[index].name = names[0];
                        tankList[index].building = names[1];
                        tankList[index].reserve = '';
                    } else if (names.length === 3) {
                        tankList[index].name = names[0];
                        tankList[index].building = names[1];
                        tankList[index].reserve = names[2];
                    } else {
                        tankList[index].name = '';
                        tankList[index].building = '';
                        tankList[index].reserve = '';
                    }
                }
                console.log(AppLogger("Got tanks list - " + tankList.length));
                callBack && callBack();
            })
            .catch(error => console.log(AppLogger(error)));
    }

    start(callBack, forceRead) {

        if (forceRead || tankList.length === 0) {
            console.log(AppLogger("Start Reading Tanks"));
            tankList = [];
            this.readTanks(callBack);
        }
        else {
            callBack && callBack();
        }
    }
}