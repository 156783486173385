import axios from "axios";
import { getCustomersUri } from "app/restHelper.jsx";
import { AppLogger } from "app/Logger";
//import { LogInHelper } from "app/logInHelper";

let customerList = [];

export default class CustomersTable {

    getCustomers() {
        return customerList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)); 
    }
/*
    getCustomersIds() {
        let customersNames = [];
        let customer = new LogInHelper().getLoggedInCustomer();
        if (customer === 'admin') { // get all customers
            for (let index in customerList) {
                customersNames.push(customerList[index].id);
            }
        }
        else {
            for (let index in customerList) {
                if (customerList[index].name === customer) {
                    customersNames.push(customerList[index].id);
                    break;
                }
            }
        }

        return customersNames;
    }
*/
    getCustomer(customerId) {

        let index = customerList.findIndex(k => k.id===customerId);

        if (index !== -1){
            return customerList[index];
        }

        return null;
    }

    getCustomerName(customerId) {
        let customer = this.getCustomer(customerId);
        if (customer !== null){
            return customer.name;
        }
        else{
            return "";
        }
    }

    addCustomerToCustomers = (customer) =>{
        // check if the customer is in the table
        for (let index in customerList){
            if (customerList[index].name === customer.name){
                return;
            }
        }
        
        customerList.push(customer);

    }

    readCustomers(callBack) {
        let uri = getCustomersUri();
        console.log(AppLogger( "Customers " + uri));
        axios
            .get(uri)
            .then(response => {
                console.log(AppLogger( "Got customers list " + response.data.length));
                for (let index = 0; index < response.data.length; index++) {
                    this.addCustomerToCustomers(response.data[index]);
                }
                callBack && callBack();
            })
            .catch(error => console.log(AppLogger( error)));
    }

    addNewCustomer(name, industry, mailingList, callBack) {
        console.log(AppLogger( "add new customer " + name));

        // check if name is in the list
        for (let index in customerList) {
            if (customerList[index].name.toLowerCase() === name.toLowerCase()) {
                callBack && callBack("Customer " + name + " already exist");
                return;
            }
        }

        axios.post(getCustomersUri(),
            { "name": name, "industry": industry, "mailing_list": mailingList })
            .then(response => {
                console.log(AppLogger("adding new customer succedded"));
                callBack && callBack(null);
            })
            .catch(error => {
                console.log(AppLogger( error));
                callBack && callBack("failed to create new customer");
            });
    }

    updateCustomer(customer,callBack) {
        let url = getCustomersUri() + "/" + customer.id;
        console.log(AppLogger( "updating customer " + url));

        let json = { "name": customer.name, "industry": customer.industry, "mailing_list": customer.mailing_list };

        axios
            .put(url,json)
            .then(response => {
                console.log(AppLogger( "updating customer succedded"));
                callBack && callBack(null);
            })
            .catch(error => {
                console.log(AppLogger( error));
                callBack && callBack("failed to create update customer");
            });
    }

    deleteCustomer(id, callBack) {
        let url = getCustomersUri() + "/" + id;
        console.log(AppLogger( "deleteCustomer " + url));

        axios
            .delete(url)
            .then(response => {
                console.log(AppLogger( "deleting customer succedded"));
                callBack && callBack();
            })
            .catch(error => console.log(AppLogger( error)));
    }

    start(callBack, forceRead) {
        if (forceRead || customerList.length === 0) {
            console.log(AppLogger("start reading customers"));
            customerList = [];
            this.readCustomers(callBack);
        }
        else {
            callBack && callBack(); 
        }
    }
}