import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { LogInHelper } from "../../app/logInHelper";
import { getLang, setLang } from '../../app/lang/lang.js'
class HeaderLinks extends Component {

  constructor(props) {
    super(props);
    this.state = { lang: getLang() };
  }

  handleLogOut = () => {
    let logIn = new LogInHelper();
    logIn.logOut();
    window.location.reload();
  }

  selectLang = (lang) => {
    setLang(lang);
    this.setState({ lang: lang });
  }

  render() {
/*
    let notificationList = new NotificationHelper().get();
    const notification = (
      <div style={{color:'with'}}>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">{notificationList.length !== 0 ? notificationList.length:""}</span>
        <p>Notification</p>
      </div>
    );
*/
    return (
      <div>
        <Nav>
          {/*
          <NavItem eventKey={1} href="#">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
          
          
          <NavDropdown
            eventKey={2}
            title={notification}
            noCaret
            id="basic-nav-dropdown"
          >
            <MenuItem eventKey={2.1}>Notification 1</MenuItem>
            <MenuItem eventKey={2.2}>Notification 2</MenuItem>
            <MenuItem eventKey={2.3}>Notification 3</MenuItem>
            <MenuItem eventKey={2.4}>Notification 4</MenuItem>
            <MenuItem eventKey={2.5}>Another notifications</MenuItem>
          </NavDropdown>
          */
          /*
          <NavItem eventKey={3} href="#">
            <i className="fa fa-search" />
            <p className="hidden-lg hidden-md">Search</p>
          </NavItem>
          */}
        </Nav>
        <Nav pullRight>
          {/*
          <NavItem eventKey={1} href="#">
            Account
          </NavItem>
          
            <NavDropdown
              eventKey={2}
              title={<div style={{color:'white'}}>{this.state.lang}</div>}
              id="basic-nav-dropdown-right"
            >
              <MenuItem eventKey={2.1} onClick={() => this.selectLang('en')}>{translate('selectEn')}</MenuItem>
              <MenuItem eventKey={2.2} onClick={() => this.selectLang('es')}>{translate('selectEs')}</MenuItem>
            </NavDropdown>*/
          }
          {/* 
          <NavItem eventKey={2} href="#">
            <div style={{color:'white'}}>{getVersion()}</div>
          </NavItem>          
          <NavItem eventKey={3} href="#" onClick={() => this.handleLogOut()}>
            <div style={{color:'white'}}>{translate('logOut')}</div>
          </NavItem>
          */}
        </Nav>
      </div>
    );
  }
}

export default HeaderLinks;
