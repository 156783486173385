import { translate } from "../../app/lang/lang";
import TanksReading from "../../app/db/TankReadings";

export default class RangeHelper {
    constructor() {
        this.ranges = ['Last 1H', 'Last 6H', 'Last 12H', 'Last 24H', 'Last 2 Days', 'Select Range'];
        this.rangesValues = [1, 6, 12, 24, 24 * 2, null];
    }

    getFirstRangeInHours = () => {
        return this.rangesValues[0];
    }

    getRanges = () => {
        return this.ranges;
    }

    getRangeInHour = (index) => {
        return this.rangesValues[index];
    }

    getSelectedString = (index) => {
        if (index === this.ranges.length - 1) {
            return translate("Custom Range");
        }
        else {
            return translate(this.ranges[index]);
        }
    }

    getRangeForHourse = (timeInHours, callback, forClass) => {
        let tr = new TanksReading();
        let from = tr.getLastReadingTime() - (timeInHours * 60 * 60 * 1000);
        let to = tr.getLastReadingTime();
        this.callback = callback;
        tr.getReadingFromTo(this.rangeReady, from, to, forClass);
    }

    getRangeFromTo = (from, to, callback, tank) => {
        this.callback = callback;
        new TanksReading().getReadingFromTo(this.rangeReady, from, to, tank);
    }

    rangeReady = (range) => {
        if (range && range.length !== 0) {
            this.callback && this.callback(range);
        }
    }
}