
import { isTankFiltered } from "../views/Tanks/Tanks"
import TanksTable from "../app/db/TanksTable"

let graphFilteredTank = null;
let dataFilteredTank = null;

export function setGraphFilteredTank(tank) {
  graphFilteredTank = tank;
}
export function getGraphFilteredTank() {
  return graphFilteredTank;
}

export function setDataFilteredTank(tank) {
  dataFilteredTank = tank;
}

export function getDataFilteredTank() {
  return dataFilteredTank;
}

/// filter 
export function isFiltered(imei, filterStr) {

  if (filterStr !== "") {
    let tanks = new TanksTable().getTanks();
    for (let index in tanks) {
      if (tanks[index].imei === imei) {
        return isTankFiltered(tanks[index], filterStr)
      }
    }
  }

  return false;
}


export function getFilteredReadings(readingsData, filterStr) {
  if (filterStr !== "" && readingsData.length !== 0) {
    let readings = [...readingsData];
    for (let index = readings.length - 1; index >= 0; index--) {
      if (isFiltered(readings[index].imei, filterStr)) {
        readings.splice(index, 1);
      }
    }
    return readings;
  }
  else {
    return readingsData;
  }
}
