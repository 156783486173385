import React from "react";
import { Table, Grid, Row, Col, MenuItem, DropdownButton, FormGroup, FormControl, Button } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import UsersTable from "../../app/db/UsersTable"
import CustomersTable from "../../app/db/CustomersTable"
import { LogInHelper } from "app/logInHelper";
import { getButtonStyle } from "../../app/adminHelper";
import { isAppReady } from 'app/adminHelper'

export default class Users extends React.Component {

    constructor(props) {
        super(props);
        this.state = { users: [], filterStr: "" };
    }

    componentDidMount() {
        this.timer = setInterval(this.tick, 250);
    }

    componentWillUnmount = () => {
        clearInterval(this.timer);
    }

    tick = () => {
        if (isAppReady()) {
            new UsersTable().start(this.usersReadyCallBack, false);
            clearInterval(this.timer);
        }
    }

    usersReadyCallBack = () => {
        let usersTable = new UsersTable();
        this.setState({ users: usersTable.getUsers() });
    }

    deleteUser(user) {
        let confirmText = 'Deleting User: Are you sure you want to delete ' + user.name + " ?"
        if (window.confirm(confirmText)) {
            let usersTable = new UsersTable();
            usersTable.remove(user.name, this.usersReadyCallBack);
        }
    }

    renderActions = (user, index) => {
        let title = " User Settings ";
        return (
            <DropdownButton
                bsStyle="primary"
                bsSize="large"
                className="pe-7s-settings"
                title={title}
                key={index}
                id={`dropdown-basic-${index}`}
                style={getButtonStyle()}
            >
                <MenuItem eventKey="1" onClick={() => this.deleteUser(user)}>Delete User</MenuItem>
            </DropdownButton>
        );
    }

    renderPageButton(onClick, text) {
        return (
            <Button style={getButtonStyle()} bsStyle="primary" onClick={onClick}>{text}</Button>
        )
    }

    isFiltered = (user, custeomrName) => {
        if (this.state.filterStr.length !== 0 &&
            user.name.search(this.state.filterStr) === -1 &&
            custeomrName.search(this.state.filterStr) === -1
        ) {
            return true;
        }
        else {
            return false;
        }
    }

    renderUsers() {

        if (1) {
            return this.renderUsersTable();
        }

        return (
            this.state.users.map((user, index) => {
                let custeomrName = new CustomersTable().getCustomerName(user.customer_id);
                if (this.isFiltered(user, custeomrName)) {
                    return null;
                }
                else if (user.role.toLowerCase() !== 'admin') {
                    return (
                        <Col xs={12} sm={6} key={index}>
                            <Card
                                hCenter={true}
                                statsIcon="fa"
                                id="ManageUser"
                                title={
                                    <div>{user.name}</div>
                                }
                                category=""
                                stats={"Customer - " + custeomrName}
                                content={<center>{this.renderActions(user, index)}</center>}
                            />
                        </Col>
                    )
                }
                else {
                    return null;
                }
            }
            ));
    }

    renderUsersTable() {
        let users = this.state.users.sort((a, b) => {
            let ct = new CustomersTable();
            let ca = ct.getCustomerName(a.customer_id);
            let cb = ct.getCustomerName(b.customer_id);

            if (ca > cb) {
                return -1;
            }
            if (ca < cb) {
                return 1;
            }
            // a must be equal to b
            return 0;
        })
        return (
            <div style={{ background: 'white', paddingLeft: 16, paddingRight: 16 }}>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Action</th>
                            <th>Customer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => {
                            return this.renderTableRow(user, index)
                        })
                        }
                    </tbody>
                </Table>
            </div>
        )
    }

    renderTableRow(user) {
        let custeomrName = new CustomersTable().getCustomerName(user.customer_id);

        if (this.isFiltered(user, custeomrName)) {
            return null;
        }

        if (user.role.toLowerCase() !== 'admin') {
            return (
                <tr>
                    <td>{user.name}</td>
                    <td>
                        {
                            this.renderPageButton(
                                () => this.deleteUser(user),
                                "Delete User"
                            )
                        }
                    </td>
                    <td>{custeomrName}</td>
                </tr>
            )
        }
        else {
            return null;
        }
    }

    renderFilterCard() {
        let logInHelper = new LogInHelper();
        if (logInHelper.isAdmin() === false) {
            return null
        }

        return (
            <Col md={4}>
                <Card
                    id={"filterUsers"}
                    title="Filter Users"
                    hCenter={true}
                    content={
                        <form>
                            <FormGroup controlId="formBasicText">
                                <FormControl
                                    type="text"
                                    value={this.state.value}
                                    placeholder="User / Customer"
                                    onChange={(e) => this.setState({ filterStr: e.target.value })}
                                />
                            </FormGroup>
                        </form>
                    }
                />
            </Col>
        );
    }


    render() {
        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        {this.renderFilterCard()}
                    </Row>
                    <Row>
                        {this.renderUsers()}
                    </Row>
                </Grid>
            </div>)
    }
}

