import axios from "axios";
import { getUsersUri } from "app/restHelper.jsx";
import { AppLogger } from "../Logger";

let usersList = [];

export default class UsersTable {
    //static myInstance = null;

    //static getInstance() {
    //    if (UsersTable.myInstance == null) {
    //        UsersTable.myInstance = new UsersTable();
    //    }

    //    return UsersTable.myInstance;
    //}

    //constructor() {
    //    this.users = [];
    //}

    clearUsers() {
        while (usersList.length > 0) {
            usersList.pop();
        }
    }

    readUsers(callBack) {
        this.clearUsers();
        let uri = getUsersUri();
        axios
            .get(uri)
            .then(response => {
                console.log(AppLogger( "Got users list - " + response.data.length + ""));
                for (let index = 0; index < response.data.length; index++) {
                    usersList.push(response.data[index]);
                }
                callBack && callBack();
            })
            .catch(error => console.log(AppLogger( error)));
    }

    getUser(userName) {
        for (let index in usersList) {
            if (usersList[index].name === userName) {
                return usersList[index];
            }
        }

        return null;
    }

    getUsers() {
        return usersList.sort((a,b) => (a.customer_id > b.customer_id) ? 1 : ((b.customer_id > a.customer_id) ? -1 : 0)); 
    }

    add(userName, pass, customer, role, callback) {
        //check if the user already exist
        for (let index in usersList) {
            if (usersList[index].name === userName) {
                callback && callback("user name already exist in the system");
                return;
            }
        }

        let user = { "name": userName, "passwd": pass, "customer_id": customer, "role": role };
        let uri = getUsersUri();
        axios
            .post(uri, user)
            .then(response => {
                console.log(AppLogger( "User added successfully"));
                //                this.readUsers(null);
                callback && callback(null);
                usersList.push(user);

            })
            .catch(error => {
                console.log(AppLogger( error));
                callback && callback("Failed to add user");
            }
            );
    }

    remove(userName, callback) {
        let uri = getUsersUri() + '/' + userName;
        axios
            .delete(uri)
            .then(response => {
                console.log(AppLogger( "deleting user successfully"));
                this.readUsers(callback);
            })
            .catch(error => {
                console.log(AppLogger( error));
                callback && callback();
            }
            );
    }

    update(userName, pass) {
        for (let index in usersList) {
            if (usersList[index].name === userName.name) {
                usersList[index].password = pass;
                return;
            }
        }
    }

    start(callBack, forceRead) {

        if (forceRead || usersList.length === 0) {
            console.log(AppLogger( "start - reading users"));
            this.readUsers(callBack);
        }
        else {
            callBack && callBack();
        }
    }
}