import React from 'react';
import { curveCatmullRom } from 'd3-shape';
import "../../../node_modules/react-vis/dist/style.css"
import { getFormattedTime } from "app/adminHelper.jsx"
import {
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    FlexibleWidthXYPlot,
    AreaSeries,
    DiscreteColorLegend,
    Crosshair
} from 'react-vis';

export default class VisGraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            crosshairValues: [],
            value: null,
            values: null,

        };
        this.air = []
        this.colors = ['#323EDD', '#E00543', '#27AA80', '#FF9A00', '#F7FD04', '#1dc7ea', '#881deae6'];
        this.props.updateColors(this.colors);
        //this.colors = ['#04322E', '#C93D1B', '#F8C43A', '#35D0BA', '#F7FD04', 'transparent'];
    }
    getData = (data) => {
        if (this.props.maxPoints === undefined || this.props.maxPoints === null) {
            return data;
        }
        else {
            if (this.props.maxPoints > data.length) {
                return data;
            }
            // let stayIndex = parseInt(data.length / this.props.maxPoints, 10);
            // for (var i = data.length - 2; i > 0; i--) {
            //     if (i % stayIndex !== 0) {
            //         data.splice(i, 1);
            //     }
            // }
            return data;
        }
    }

    myFormatter = (t, i) => {
        let formatedTime = getFormattedTime(t);
        let splitted = formatedTime.split('-');

        return (<tspan>
            <tspan x="0" dy="1em">{splitted[0]}</tspan>
            <tspan x="0" dy="1em">{splitted[1]}</tspan>
        </tspan>);
    }

    timeFormatForHint = (t) => {
        let formatedTime = getFormattedTime(t);
        let splitted = formatedTime.split('-');

        return (
            <div>
                <div>{splitted[0]}</div>
                <div>{splitted[1]}</div>
            </div>
        );
    }

    yFormatter = (t, i) => {

        return (
            <div>
                {(t / 1000).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + "K L"}
            </div>
        )

    }

    getLegendItems = () => {
        let items = [];
        for (let index in this.props.data) {
            let data = this.props.data[index];
            if (data.length !== 0) {
                if (data[0].tankName !== "") { // not a real tank, helper points
                    let obj = { title: data[0].tankName, color: this.colors[index % this.colors.length] };
                    items.push(obj);
                }
            }
        }
        return items;
    }

    getxValues = () => {
        const ticksForSmallScreen = 6;
        let tickTotal = (window.innerWidth <= 994) ? ticksForSmallScreen : 12;

        // get the data array
        for (let index in this.props.data) {
            let dataLen = this.props.data[index].length;
            if (dataLen > 2) {
                let ret = [];
                let data = this.props.data[index];
                let firstX = data[0].x - (data[0].x % 3600000) + 3600000;
                let hours = parseInt((data[dataLen - 1].x - data[0].x - 1000 * 60) / 1000 / 60 / 60, 10);
                let hoursJump = parseInt(hours / 6, 10);
                let jumps = 3600000 * (hoursJump + 1);
                if (tickTotal > ticksForSmallScreen) {
                    jumps /= 2;
                    if (data[0].x < firstX - jumps) {
                        firstX -= jumps;
                    }
                }
                else {
                    if (hours > 12) {
                        tickTotal += 1;
                    }
                }
                for (let index = 0; index < tickTotal; index++) {
                    ret.push(firstX + index * jumps);
                }
                return ret;
            }
        }
        return null
    }

    rememberValue = value => {
        let values = [value, null];
        this.setState({ value, values });
        let index = this.air.findIndex(element => element.tankName === value.tankName)
        if (index !== -1) {
            this.air[index].liquidHigh = value.liquidHigh;
            this.air[index].x = value.x;
            this.air[index].y = value.y;
        }
        else {
            this.air.push(value);
        }
        this.props.updateAirValues(this.air);
        //onsole.log(this.air);
    };

    render() {
        const { values } = this.state;

        let legenItems = this.getLegendItems();
        let xValues = this.getxValues();
        const configuredCurve = curveCatmullRom.alpha(0.5);
        return (
            <div>
                <FlexibleWidthXYPlot
                    height={360}
                    margin={{ left: 50 }}
                    sort={false}>
                    <DiscreteColorLegend
                        items={legenItems}
                        orientation="horizontal" />
                    <HorizontalGridLines style={{ stroke: '#87cefa' }} />
                    <VerticalGridLines tickValues={xValues} style={{ stroke: '#B7E9ED' }} />
                    <XAxis title="" tickValues={xValues} tickFormat={this.myFormatter} />
                    <YAxis title="" tickTotal={15} style={{ size: 10 }} tickFormat={(v) => {
                        if (v > 1000) {
                            return (v / 1000) + "K g";
                        }
                        else {
                            return v + " g";
                        }
                    }} />

                    {this.props.data.map((data, index) => {
                        //let strokeColor = 'blue';
                        if (index !== 0) {
                            //strokeColor = 'white';
                        }
                        return (
                            <AreaSeries
                                key={index}
                                xType={'time'}
                                className={"series" + { index }}
                                opacity={1}
                                fill={this.colors[index % this.colors.length]}
                                data={this.getData(data)}
                                curve={configuredCurve}
                                stroke={this.colors[index % this.colors.length]}
                                size={1}
                                style={{ strokeWidth: "2.5", fillOpacity: "0.1" }}
                                onNearestXY={(datapoint, event) => {
                                    if (datapoint.tankName.length !== 0) {
                                        this.rememberValue(datapoint);
                                    }
                                }}
                            />)
                    })}
                    {values ?
                        <Crosshair values={values}>
                            <div style={{ color: 'blue', padding: '1px' }}>
                                < b >
                                    {/* <div><center>{parseInt(values[0].y, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}L</center></div> */}
                                    <div><center>{this.timeFormatForHint(values[0].x)}</center></div>
                                </b>
                            </div>
                        </Crosshair > : null
                    }
                </FlexibleWidthXYPlot>
            </div >
        );
    }
}