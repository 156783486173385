import React from "react";
import { Grid, Row, Col, Button, Alert } from "react-bootstrap";
import { renderTanks } from "../Tanks/Tanks.jsx"
import TanksReading from "../../app/db/TankReadings.jsx";
import { isAppReady } from 'app/adminHelper'
import { Card } from "../../components/Card/Card"
import { isTankFiltered, renderFilterTanksCard } from "../Tanks/Tanks";
import { AlertsForm } from "./AlerstForm";
import TanksTable from "../../app/db/TanksTable";
import NotificationHelper from "../../app/notificationHelper"
import { levelAlertView, heartBeatView } from "../Notifications/TanksNotifications"
import Spinner from "../App/Spinner";
import { getButtonStyle } from "../../app/adminHelper.jsx";
import { getTankComponent } from "../../components/App/TankTypes.js";

export default class Alerts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            appIsredy: false,
            showAlerstForm: false,
            tank: null,
            filterStr: ""
        };
        this.counter = 1;
    }

    componentDidMount() {
        this.timer = setInterval(this.isAppReady, 1000);
        this.isAppReady();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    isAppReady = () => {
        if (isAppReady()) {
            this.setState({ appIsredy: true });
        }

        if (this.state.showAlerstForm === false && this.counter++ % 60 === 0) { // refresh view to show new alerts
            this.setState({ appIsredy: false });
        }
    }

    renderAlertsTanks = () => {
        let readings = new TanksReading().getLevelMonitoring("name");
        return renderTanks(readings, true);
    }

    showAlertsForm = (tank) => {
        this.setState({ showAlerstForm: true, tank: tank });
    }

    closeAlertsForm = () => {
        this.setState({ showAlerstForm: false, tank: null, customer: null });
    }

    renderAlertsAndEdit = (tank) => {
        console.log("renderAlertsAndEdit");
        let nh = new NotificationHelper();
        let levelNotification = nh.getLevelNotificationsOnTank(tank);
        let hearBeatNotification = nh.getHeartBeatNotificationForTank(tank);
        let editAlert = <center><Button bsStyle="primary" style={getButtonStyle()} onClick={() => this.showAlertsForm(tank)}>Edit Alerts</Button></center>;
        if (levelNotification.length === 0 && hearBeatNotification.length === 0) {
            return (
                <div>
                    <Alert bsStyle="info" className="alert-with-icon">
                        <center>
                            <span data-notify="icon" className="pe-7s-bell" />
                            <span data-notify="message">No Alerts On This Tank<q>{alert.name}</q></span>
                        </center>
                    </Alert>
                    {editAlert}
                </div>
            );
        }
        else {
            return (
                <div>
                    {levelAlertView(levelNotification)}
                    {heartBeatView(hearBeatNotification)}
                    {editAlert}
                </div>
            )
        }
    }


    renderTanks = () => {
        let readings = new TanksReading().getLevelMonitoring("name");
        console.log("renderTanks");
        let nh = new NotificationHelper();
        return (
            readings.map((reading, index) => {
                let tt = new TanksTable();
                let tank = tt.getTank(reading.imei);
                if (tank === null) {
                    return null;
                }
                let asAlerts = nh.getLevelNotificationsOnTank(tank).length !== 0 ? true : false

                if (isTankFiltered(tank, this.state.filterStr)) {
                    return null;
                }
                else {
                    let volume = parseInt(tank.capacity * reading.actual_level / 100, 10) + " L";
                    return (
                        < Col xs={12} md={4} key={index} >
                            <center>
                                <Card
                                    hCenter={true}
                                    statsIcon="fa"
                                    id="EditTank"
                                    title={
                                        <div>
                                            <div>{tank.name}</div>
                                        </div>}
                                    category=""
                                    stats={this.renderAlertsAndEdit(tank)}
                                    content={getTankComponent(tank, reading.actual_level, volume, asAlerts)}
                                />
                            </center>
                        </Col>
                    )
                }
            }
            )
        )
    }


    render() {
        if (this.state.appIsredy === false) {
            return (
                <Card
                    hCenter={true}
                    title="Loading ..."
                    content={<center><Spinner /></center>}
                />
            );
        }

        return (
            <div className="content">
                <Grid fluid>
                    <Row>
                        {renderFilterTanksCard(this)}
                    </Row>
                    <Row>
                        {this.renderTanks()}
                    </Row>
                </Grid>
                <AlertsForm show={this.state.showAlerstForm}
                    tank={this.state.tank}
                    onHide={() => this.closeAlertsForm()} />
            </div>
        );
        /*
                return (
                    <div className="content">
                        <Grid fluid>
                            <Row>
                                {this.renderAlertsTanks()}
                            </Row>
                        </Grid>
                    </div>) */
    }

}

