
export function makePdf(exportOrPrint, pdfDocDefinition) {
    var docDefinition = {
        compress: true,
        content: pdfDocDefinition
    };
    //window.pdfMake.vfs = window.vsf_fonts.pdfMake.vfs;
    window.pdfMake.tableLayouts = {
        exampleLayout: {
            hLineWidth: function (i, node) {
                if (i === 0 || i === node.table.body.length) {
                    return 0;
                }
                return (i === node.table.headerRows) ? 2 : 1;
            },
            vLineWidth: function (i) {
                return 0;
            },
            hLineColor: function (i) {
                return i === 1 ? 'black' : '#aaa';
            },
            paddingLeft: function (i) {
                return i === 0 ? 0 : 8;
            },
            paddingRight: function (i, node) {
                return (i === node.table.widths.length - 1) ? 0 : 8;
            }
        }
    };

    // download the PDF
    if (exportOrPrint === 'export') {
        window.pdfMake.createPdf(docDefinition).download('NTCL-MonitoringData.pdf');
    }
    if (exportOrPrint === 'print') {
        window.pdfMake.createPdf(docDefinition).print({},window);
    }
}